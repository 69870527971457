import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnPopover } from '../cn-popover';
var CnEllipsis = /** @class */ (function (_super) {
    __extends(CnEllipsis, _super);
    function CnEllipsis(props) {
        var _this = _super.call(this, props) || this;
        _this.onMouseEnter = function () {
            if (_this.textRef && _this.textRef.scrollWidth > _this.textRef.offsetWidth) {
                _this.setState({
                    isOverflow: true,
                });
            }
            else {
                _this.setState({
                    isOverflow: false,
                });
            }
        };
        _this.saveTextRef = function (ref) {
            _this.textRef = ref;
        };
        _this.state = {
            isOverflow: false,
        };
        return _this;
    }
    CnEllipsis.prototype.componentDidMount = function () {
        if (this.props.ellipsisPosition === 'middle') {
            if (this.textRef && this.textRef.scrollWidth > this.textRef.offsetWidth) {
                this.setState({
                    isOverflow: true,
                });
            }
        }
    };
    CnEllipsis.prototype.render = function () {
        var isOverflow = this.state.isOverflow;
        var _a = this.props, children = _a.children, className = _a.className, ellipsisPosition = _a.ellipsisPosition, endCharCount = _a.endCharCount, hasTooltip = _a.hasTooltip, cnTooltipProps = _a.cnTooltipProps, otherProps = __rest(_a, ["children", "className", "ellipsisPosition", "endCharCount", "hasTooltip", "cnTooltipProps"]);
        var classes = cx(className, {
            'cn-ui-m-ellipsis': true,
        });
        // 默认
        var trigger = (React.createElement("div", __assign({ onMouseEnter: this.onMouseEnter, className: classes }, otherProps),
            React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-m-ellipsis-header" }, children)));
        // 省略号出现在中间
        if (isOverflow && ellipsisPosition === 'middle') {
            var childrenStr = "".concat(children);
            var startChildren = childrenStr.substr(0, childrenStr.length - endCharCount);
            var endChildren = childrenStr.substr(-endCharCount);
            trigger = (React.createElement("div", __assign({ className: classes }, otherProps),
                React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-m-ellipsis-header" }, startChildren),
                React.createElement("div", { className: "cn-ui-m-ellipsis-footer" }, endChildren)));
        }
        // 出现 Balloon
        if (isOverflow && hasTooltip) {
            return (React.createElement(CnPopover, __assign({ content: children, trigger: "click", placement: "top-start", mode: "dark" }, cnTooltipProps), trigger));
        }
        return trigger;
    };
    CnEllipsis.displayName = 'CnEllipsis';
    CnEllipsis.defaultProps = {
        ellipsisPosition: 'end',
        endCharCount: 8,
        hasTooltip: true,
    };
    return CnEllipsis;
}(React.Component));
export { CnEllipsis };
