import './styles/index.scss';
import { withI18n } from 'panda-i18n';
import locale from '@/locale';
import { CnEmbedPicker } from './embed-picker';
import { CnTimePicker as UICnTimePicker, } from './time-picker';
var CnTimePicker = withI18n(UICnTimePicker, { locale: locale });
// @ts-ignore
var CnTimePickerPro = CnTimePicker;
CnTimePicker.displayName = 'CnTimePickerPro';
CnTimePickerPro.CnEmbedPicker = CnTimePicker;
export { CnEmbedPicker, CnTimePickerPro, CnTimePicker };
