import * as React from 'react';
var counter = 0;
export function guid(prefix) {
    if (prefix === void 0) { prefix = ''; }
    counter += 1;
    return "".concat(prefix).concat(counter);
}
export function useGuid(prefix) {
    if (prefix === void 0) { prefix = ''; }
    var id = React.useRef(guid(prefix));
    return id.current;
}
