import { __assign } from "tslib";
import './index.scss';
import React, { useEffect } from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnPageFooter } from '@/components/cn-page-footer';
import { useControlled, withNativeProps } from '@cainiaofe/cn-ui-common';
import { MoreMenu } from './more-menu';
import classNames from 'classnames';
export var CnBottomNavBar = function (props) {
    var menuDataSource = props.menuDataSource, _a = props.lowCodeMenuDataSource, lowCodeMenuDataSource = _a === void 0 ? [] : _a;
    var _b = useControlled(props, {
        defaultValuePropName: 'defaultActiveKey',
        valuePropName: 'activeKey',
    }), navActiveKey = _b[0], setNavActiveKey = _b[1];
    var _c = React.useState([]), menuData = _c[0], setMenuData = _c[1];
    var _d = React.useState(false), moreDrawerVisible = _d[0], setMoreDrawerVisible = _d[1];
    var _e = React.useState(false), withMoreClassName = _e[0], setWithMoreClassName = _e[1];
    var setTimeoutRef = React.useRef(null);
    var cnBottomNavBarRef = React.useRef(null);
    useEffect(function () {
        if (setTimeoutRef.current) {
            clearTimeout(setTimeoutRef.current);
            setTimeoutRef.current = null;
        }
        if (!moreDrawerVisible) {
            setTimeoutRef.current = setTimeout(function () {
                setWithMoreClassName(moreDrawerVisible);
            }, 500);
        }
        else {
            setWithMoreClassName(moreDrawerVisible);
        }
    }, [moreDrawerVisible]);
    var formatLowcodeData = function () {
        return lowCodeMenuDataSource.map(function (item) {
            return __assign(__assign({}, item), { title: item.text, icon: item.icon || 'module-fill', url: item.actualPath, children: item.children });
        });
    };
    // 低码使用时判断当前路由是否在menuData中
    var judgeInLowcodeShow = function () {
        var showNavBar = false;
        if (lowCodeMenuDataSource.length) {
            var locPathname_1 = window.location.pathname;
            var checkLowcodeMenu_1 = function (menu) {
                if (!Array.isArray(menu) || menu.length === 0)
                    return;
                menu.forEach(function (item) {
                    if (item.actualPath === locPathname_1) {
                        showNavBar = true;
                    }
                    if (Array.isArray(item.children) && item.children.length) {
                        checkLowcodeMenu_1(item.children);
                    }
                });
            };
            checkLowcodeMenu_1(lowCodeMenuDataSource);
        }
        else {
            showNavBar = true;
        }
        return showNavBar;
    };
    React.useEffect(function () {
        // 格式化低码菜单数据
        if (lowCodeMenuDataSource.length) {
            var newMenuData = formatLowcodeData();
            setMenuData(newMenuData);
        }
        else if (Array.isArray(menuDataSource) && (menuDataSource === null || menuDataSource === void 0 ? void 0 : menuDataSource.length) > 0) {
            setMenuData(menuDataSource);
        }
    }, []);
    var _f = React.useMemo(function () {
        if (!menuData || menuData.length === 0 || !judgeInLowcodeShow()) {
            return {
                showMenuData: [],
                hideMenuData: [],
                hideMenuKeys: [],
                showMore: false,
            };
        }
        if (menuData.length <= 5) {
            // 判断 menuData 是否有 二级菜单
            var hasSecondary = menuData.filter(function (item) { var _a; return Array.isArray(item.children) && ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length); });
            return {
                showMenuData: menuData,
                // 有二级菜单时，显示更多
                showMore: hasSecondary.length > 0,
                hideMenuData: [],
                hideMenuKeys: [],
            };
        }
        var _hideMenuData = menuData.slice(4, menuData.length);
        return {
            showMenuData: menuData.slice(0, 4),
            hideMenuData: _hideMenuData,
            hideMenuKeys: _hideMenuData.map(function (item) { return item.key; }),
            showMore: true,
        };
    }, [judgeInLowcodeShow, menuData]), showMenuData = _f.showMenuData, hideMenuKeys = _f.hideMenuKeys, showMore = _f.showMore;
    var showBottomNavBar = React.useMemo(function () {
        if (showMenuData === null || showMenuData === void 0 ? void 0 : showMenuData.length) {
            var currentItem = showMenuData.find(function (item) { return item.key === navActiveKey; });
            return !!currentItem;
        }
        return false;
    }, [showMenuData, navActiveKey]);
    if (!showBottomNavBar)
        return null;
    return withNativeProps(props, React.createElement(CnPageFooter, { key: navActiveKey, className: classNames('cn-bottom-nav-bar-page-footer', {
            'cn-bottom-nav-bar-page-footer-more': moreDrawerVisible || withMoreClassName,
        }), isOuterCnPage: true, noPadding: true, "data-testid": "cn-bottom-nav-bar-page-footer" },
        React.createElement("div", { className: "cn-bottom-nav-bar", ref: cnBottomNavBarRef }, showMenuData === null || showMenuData === void 0 ? void 0 :
            showMenuData.map(function (item) { return (React.createElement("div", { onClick: function () {
                    var key = item.key;
                    if (key !== undefined && key !== null) {
                        setNavActiveKey(key, item);
                    }
                    if (moreDrawerVisible) {
                        setMoreDrawerVisible(false);
                    }
                }, className: classNames('cn-bottom-nav-bar-item', {
                    'cn-bottom-nav-bar-item-active': moreDrawerVisible
                        ? false
                        : navActiveKey && (item === null || item === void 0 ? void 0 : item.key) === navActiveKey,
                }), key: item.key },
                React.createElement(CnIcon, { outerClassName: "cn-bottom-nav-bar-item-icon", size: "xl", type: item.icon }),
                React.createElement("div", { className: "cn-bottom-nav-bar-item-title" }, item.title))); }),
            showMore ? (React.createElement(MoreMenu, { activeKey: navActiveKey, dataSource: menuData, hideMenuKeys: hideMenuKeys, moreDrawerVisible: moreDrawerVisible, updateMoreDrawerVisible: function (visible) {
                    setMoreDrawerVisible(visible);
                }, updateActiveKey: function (key, activeItem) {
                    setNavActiveKey(key, activeItem);
                }, boxRef: cnBottomNavBarRef })) : null)));
};
CnBottomNavBar.displayName = 'CnBottomNavBar';
