export function getWidth(id) {
    var ele = document.getElementById(id);
    if (ele) {
        return ele.getBoundingClientRect().width;
    }
    return 0;
}
/**
 * 更多按钮的宽度
 */
export var MORE_BUTTON_WIDTH = {
    small: 28 + 8,
    medium: 38 + 8,
    large: 65 + 8,
};
/**
 * 转换设计稿px值为实际px值
 */
export var getRealPxValue = function (value) {
    var scale = document.documentElement.clientWidth / 750;
    return value * scale;
};
