import { __assign } from "tslib";
import * as React from 'react';
import { CnFormField } from '@/form/cn-form-field';
import { CnFilter as CnFilterView } from './cn-filter';
import locale from '@/locale';
import { withI18n } from 'panda-i18n';
import './index.scss';
var CnFilterItem = function (props) {
    return React.createElement(CnFormField, __assign({ title: props.label }, props));
};
CnFilterItem.displayName = 'CnFilterItem';
var CnFilterProItem = function (props) {
    return React.createElement(CnFilterItem, __assign({}, props));
};
CnFilterProItem.displayName = 'CnFilterProItem';
var CnFilter = withI18n(CnFilterView, { locale: locale });
var CnFilterPro = function (props) {
    return React.createElement(CnFilter, __assign({}, props));
};
CnFilterPro.displayName = 'CnFilterPro';
export { CnFilter, CnFilterPro, CnFilterItem, CnFilterProItem };
