/**
 * 格式化输出值
 * @param v dayjs格式日期值
 * @param fmt 格式
 * @returns
 */
export var formatValue = function (v, fmt) {
    if (!v || !fmt)
        return v || undefined;
    var formatter = function (i, idx) {
        var _fmt = fmt;
        if (Array.isArray(fmt)) {
            _fmt = fmt[idx];
        }
        if (typeof _fmt === 'function') {
            return _fmt(i);
        }
        if (i && i.format) {
            return i.format(_fmt);
        }
        return null;
    };
    return Array.isArray(v) ? v.map(function (i, idx) { return formatter(i, idx); }) : formatter(v);
};
/**
 * 根据精度返回format字符串
 * @param precision 精度
 * @returns formatStrig
 */
export var getFormatStrFromPrecision = function (precision) {
    if (precision === 'day') {
        return 'YYYY-MM-DD';
    }
    else if (precision === 'hour') {
        return 'YYYY-MM-DD HH:00:00';
    }
    else if (precision === 'minute') {
        return 'YYYY-MM-DD HH:mm:00';
    }
    else if (precision === 'second') {
        return 'YYYY-MM-DD HH:mm:ss';
    }
    else if (precision === 'month') {
        return 'YYYY-MM';
    }
    else if (precision === 'year') {
        return 'YYYY';
    }
    else {
        return 'YYYY-MM-DD';
    }
};
/**
 * 根据format和showTime映射出precision
 * @param format 格式化字符串
 * @param showTime 是否显示时间
 * @returns
 */
export var getPrecisionFromFormat = function (format, showTime) {
    if (showTime) {
        if (format === 'YYYY-MM-DD HH:mm') {
            return 'minute';
        }
        if (format === 'YYYY-MM-DD HH') {
            return 'hour';
        }
        return 'second';
    }
    if (format === 'YYYY-MM') {
        return 'month';
    }
    if (format === 'YYYY') {
        return 'year';
    }
    return 'day';
};
/**
 * 获取时间戳
 * @param v dayjs格式日期值
 * @param processTime 是否处理时间开始/结束
 * @param endOfDay 是否取时间结束
 * @returns
 */
export var getValueFromDayjs = function (v, processTime, endOfDay) {
    var fmt = function () {
        var getValue = function (p, type) {
            if (!p || !p.valueOf) {
                return null;
            }
            if (!processTime) {
                return p.valueOf();
            }
            if (type) {
                return p.endOf('date').valueOf();
            }
            return p.startOf('date').valueOf();
        };
        if (Array.isArray(endOfDay)) {
            return endOfDay.map(function (type) { return function (i) {
                return getValue(i, type);
            }; });
        }
        return function (i) { return getValue(i, endOfDay); };
    };
    return formatValue(v, fmt());
};
