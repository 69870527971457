import { __assign } from "tslib";
import React, { forwardRef, useCallback, useEffect, } from 'react';
import dayjs from 'dayjs';
import { withNativeProps } from '@/utils/with-native-props';
import { CnPickerView } from '@/components/cn-picker-view';
import { getNearestDate, getRangeDate, getRealRange, isValidDate, toDate, } from '@/utils/date';
import { dateToPicker, DEFAULT_FORMAT, getColumns, getTimeOption, pickerToDate, } from './utils';
import { usePropsValue } from '@/utils/use-props-value';
var EmbedPicker = function (props, ref) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, 
    // value: valueProp,
    // defaultValue,
    onChange = props.onChange, className = props.className, hourStep = props.hourStep, minuteStep = props.minuteStep, secondStep = props.secondStep, _b = props.renderTimeMenuItems, renderTimeMenuItems = _b === void 0 ? function (v) { return v; } : _b, _c = props.format, format = _c === void 0 ? DEFAULT_FORMAT : _c, 
    // disabledHours,
    // disabledMinutes,
    // disabledSeconds,
    validRangeProp = props.validRange;
    var options = getTimeOption(props);
    var validRange = getRealRange(options, props, validRangeProp);
    var isControlled = 'value' in props;
    var _d = usePropsValue({
        value: props.value,
        defaultValue: props.defaultValue,
    }), value = _d[0], setValue = _d[1];
    useEffect(function () {
        function fitValue(v) {
            var d = toDate(v);
            var x = getRangeDate(isValidDate(d) ? d : new Date(), {
                time: options,
            }, validRange);
            if (validRange &&
                (secondStep !== 1 || minuteStep !== 1 || hourStep !== 1)) {
                var xt = dayjs(x).format(format);
                if (xt !== dayjs(validRange[0]).format(format) &&
                    xt !== dayjs(validRange[1]).format(format)) {
                    // 不在边界，将其设置为最靠近的值
                    return getNearestDate(options, props, x, 'auto');
                }
            }
            return x;
        }
        var fit = fitValue(value);
        if (onChange) {
            onChange(fit);
        }
        if (!isControlled) {
            setValue(fit);
        }
    }, [value, isControlled]);
    // Generate selects
    var dataSource = getColumns(value, options, __assign({}, props));
    var doRenderTimeMenuItems = useCallback(function (v) {
        return v.map(function (e) { return renderTimeMenuItems(e); });
    }, [renderTimeMenuItems]);
    var handleChange = function (values) {
        var d = getRangeDate(pickerToDate(values.slice(0), options), {
            time: options,
        }, validRange);
        if (onChange) {
            onChange(d);
        }
        if (!isControlled) {
            setValue(d);
        }
    };
    var pickerValue = dateToPicker(value, options);
    return withNativeProps(props, React.createElement(CnPickerView, { ref: ref, columns: doRenderTimeMenuItems(dataSource), value: pickerValue, className: className, prefix: prefix, onChange: handleChange }, function (_, actions) { var _a; return (_a = props.children) === null || _a === void 0 ? void 0 : _a.call(props, value, actions); }));
};
var CnEmbedPicker = forwardRef(EmbedPicker);
export { CnEmbedPicker };
