import { __assign } from "tslib";
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import './my-watermark.scss';
var defaultProps = {
    width: 120,
    height: 64,
};
var defaultOptions = {
    gapX: 24,
    gapY: 48,
    rotate: -22,
    fontWeight: 'normal',
    fontSize: 14,
    fontFamily: 'sans-serif',
    fontColor: '#E8EAEC',
    fontStyle: 'normal',
};
export var CnWaterMark = function (p) {
    var props = __assign(__assign(__assign({}, defaultOptions), defaultProps), p);
    var _a = props.zIndex, zIndex = _a === void 0 ? 2000 : _a, image = props.image, _b = props.imageWidth, imageWidth = _b === void 0 ? 120 : _b, _c = props.imageHeight, imageHeight = _c === void 0 ? 64 : _c, content = props.content, fontStyle = props.fontStyle, fontColor = props.fontColor, gapX = props.gapX, gapY = props.gapY, width = props.width, height = props.height, rotate = props.rotate, fontSize = props.fontSize, fontFamily = props.fontFamily, fontWeight = props.fontWeight;
    var _d = useState(''), base64Url = _d[0], setBase64Url = _d[1];
    useEffect(function () {
        var canvas = document.createElement('canvas');
        var ratio = window.devicePixelRatio;
        var ctx = canvas.getContext('2d');
        var canvasWidth = "".concat((gapX + width) * ratio, "px");
        var canvasHeight = "".concat((gapY + height) * ratio, "px");
        var markWidth = width * ratio;
        var markHeight = height * ratio;
        canvas.setAttribute('width', canvasWidth);
        canvas.setAttribute('height', canvasHeight);
        if (ctx) {
            if (image) {
                ctx.translate(markWidth / 2, markHeight / 2);
                ctx.rotate((Math.PI / 180) * Number(rotate));
                var img_1 = new Image();
                img_1.crossOrigin = 'anonymous';
                img_1.referrerPolicy = 'no-referrer';
                img_1.src = image;
                img_1.onload = function () {
                    ctx.drawImage(img_1, (-imageWidth * ratio) / 2, (-imageHeight * ratio) / 2, imageWidth * ratio, imageHeight * ratio);
                    ctx.restore();
                    setBase64Url(canvas.toDataURL());
                };
            }
            else if (content) {
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                // 文字绕中间旋转
                ctx.translate(markWidth / 2, markHeight / 2);
                ctx.rotate((Math.PI / 180) * Number(rotate));
                var markSize = Number(fontSize) * ratio;
                ctx.font = "".concat(fontStyle, " normal ").concat(fontWeight, " ").concat(markSize, "px/").concat(markHeight, "px ").concat(fontFamily);
                ctx.fillStyle = fontColor;
                ctx.fillText(content, 0, 0);
                ctx.restore();
                setBase64Url(canvas.toDataURL());
            }
        }
        else {
            throw new Error('Canvas is not supported');
        }
    }, [
        gapX,
        gapY,
        rotate,
        fontStyle,
        fontWeight,
        width,
        height,
        fontFamily,
        fontColor,
        image,
        content,
        fontSize,
        imageHeight,
        imageWidth,
    ]);
    return withNativeProps(props, React.createElement("div", { style: {
            zIndex: zIndex,
            backgroundSize: "".concat(gapX + width, "px"),
            backgroundImage: "url('".concat(base64Url, "')"),
        }, className: cx(['cn-ui-m-water-mark', 'cn-ui-m-styled-water-mark']), "data-testid": "cn-water-mark" }));
};
