import { __assign, __extends, __rest } from "tslib";
/** 声明：该组件代码copy了fusion/next的box组件后做修改 */
import React, { Component } from 'react';
import cx from 'classnames';
import { createStyle, filterHelperStyle, filterInnerStyle, filterOuterStyle, getChildMargin, getMargin, getSpacingHelperMargin, } from './create-style';
/**
 * 遍历box子元素，对于fragement元素取一层下级子元素，避免使用fragement时无法自动计算边距的问题
 * @param children box的子元素
 * @returns 整理后的元素列表
 */
function getChildrenWithoutFragment(children) {
    var result = [];
    React.Children.toArray(children).forEach(function (item) {
        // @ts-ignore type
        if (typeof item === 'object' && item.type === React.Fragment) {
            // @ts-ignore props
            result = result.concat(item.props.children);
        }
        else {
            result.push(item);
        }
    });
    return result;
}
var createChildren = function (children, _a) {
    var spacing = _a.spacing, direction = _a.direction, wrap = _a.wrap;
    var array = getChildrenWithoutFragment(children);
    if (!children) {
        return null;
    }
    return array.map(function (child, index) {
        var spacingMargin = {};
        spacingMargin = getChildMargin(spacing);
        if (!wrap) {
            // 不折行
            var isNone_1 = [index === 0, index === array.length - 1];
            var props_1 = direction === 'row'
                ? ['marginLeft', 'marginRight']
                : ['marginTop', 'marginBottom'];
            ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'].forEach(function (prop) {
                if (prop in spacingMargin && props_1.indexOf(prop) === -1) {
                    spacingMargin[prop] = 0;
                }
                props_1.forEach(function (key, i) {
                    if (key in spacingMargin && isNone_1[i]) {
                        spacingMargin[key] = 0;
                    }
                });
            });
        }
        if (React.isValidElement(child)) {
            var propsMargin = child.props.margin;
            var childPropsMargin = getMargin(propsMargin);
            var gridProps = {};
            // @ts-ignore
            if (['function', 'object'].indexOf(typeof child.type) > -1 &&
                child.type._typeMark === 'responsive_grid') {
                gridProps = createStyle(__assign({ display: 'grid' }, child.props));
            }
            return React.cloneElement(child, {
                style: __assign(__assign(__assign(__assign({}, spacingMargin), childPropsMargin), gridProps), (child.props.style || {})),
            });
        }
        return child;
    });
};
var getStyle = function (style, props) {
    if (style === void 0) { style = {}; }
    return __assign(__assign({}, createStyle(__assign({ display: 'flex' }, props))), style);
};
var getOuterStyle = function (style, styleProps) {
    var sheet = getStyle(style, styleProps);
    return filterOuterStyle(sheet);
};
var getHelperStyle = function (style, styleProps) {
    var sheet = getStyle(style, styleProps);
    return filterHelperStyle(__assign(__assign({}, sheet), getSpacingHelperMargin(styleProps.spacing)));
};
var getInnerStyle = function (style, styleProps) {
    var sheet = getStyle(style, styleProps);
    return filterInnerStyle(sheet);
};
/**
 * Box
 */
var CnBox = /** @class */ (function (_super) {
    __extends(CnBox, _super);
    function CnBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnBox.prototype.render = function () {
        var _a = this.props, prefix = _a.prefix, direction = _a.direction, justify = _a.justify, align = _a.align, wrap = _a.wrap, flex = _a.flex, spacing = _a.spacing, padding = _a.padding, margin = _a.margin, style = _a.style, className = _a.className, children = _a.children, others = __rest(_a, ["prefix", "direction", "justify", "align", "wrap", "flex", "spacing", "padding", "margin", "style", "className", "children"]);
        var styleProps = {
            direction: direction,
            justify: justify,
            align: align,
            wrap: wrap,
            flex: flex,
            spacing: spacing,
            padding: padding,
            margin: margin,
        };
        var styleSheet = getStyle(style, styleProps);
        var boxs = createChildren(children, {
            spacing: spacing,
            direction: direction,
            wrap: wrap,
        });
        var clsPrefix = "".concat(prefix, "box");
        var cls = cx(clsPrefix, className);
        if (wrap && spacing) {
            var outerStyle = getOuterStyle(style, styleProps);
            var helperStyle = getHelperStyle(style, styleProps);
            var innerStyle = getInnerStyle(style, styleProps);
            return (React.createElement("div", __assign({ style: outerStyle, className: cls }, others),
                React.createElement("div", { style: helperStyle },
                    React.createElement("div", { style: innerStyle, className: clsPrefix }, boxs))));
        }
        return (React.createElement("div", __assign({ style: styleSheet, className: cls }, others), boxs));
    };
    CnBox.defaultProps = {
        prefix: 'cn-ui-m-',
        direction: 'column',
        wrap: false,
    };
    return CnBox;
}(Component));
export { CnBox };
