import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import './cn-radio.scss';
var getInitCheckedStatus = function (props) {
    if ('checked' in props) {
        return props.checked;
    }
    else if (props.defaultChecked) {
        return props.defaultChecked;
    }
};
export var CnRadio = function (props) {
    var _a = props.size, size = _a === void 0 ? 'medium' : _a, children = props.children, checked = props.checked, onChange = props.onChange, disabled = props.disabled, className = props.className, defaultChecked = props.defaultChecked, readOnly = props.readOnly, onClick = props.onClick, others = __rest(props, ["size", "children", "checked", "onChange", "disabled", "className", "defaultChecked", "readOnly", "onClick"]);
    var prefix = 'cn-ui-m-';
    var _b = React.useState(getInitCheckedStatus(props)), selected = _b[0], setSelected = _b[1];
    var selectedPrefix = selected ? '--selected' : '--unselected';
    var disabledPrefix = disabled ? '-disabled' : '';
    var circleCls = classNames([
        "".concat(prefix, "radio-circle"),
        "".concat(prefix, "radio-circle--").concat(size),
        "".concat(prefix, "radio-circle").concat(selectedPrefix).concat(disabledPrefix),
    ]);
    var pointCls = classNames([
        "".concat(prefix, "radio-point"),
        "".concat(prefix, "radio-point--").concat(size),
        "".concat(prefix, "radio-point").concat(selectedPrefix).concat(disabledPrefix),
    ]);
    var textCls = classNames([
        "".concat(prefix, "radio-text"),
        "".concat(prefix, "radio-text--").concat(size),
        "".concat(prefix, "radio-text").concat(selectedPrefix).concat(disabledPrefix),
    ]);
    React.useEffect(function () {
        if ('checked' in props) {
            setSelected(props.checked);
        }
    });
    var handleClick = function (e) {
        if (!disabled && !readOnly) {
            if (isFunction(onChange)) {
                onChange(!selected, e);
            }
            if (isFunction(onClick)) {
                onClick(e);
            }
            // 非受控模式
            if (!('checked' in props)) {
                setSelected(!selected);
            }
        }
    };
    var text = null;
    if (children) {
        text =
            isString(children) || isNumber(children) ? (React.createElement("span", { className: textCls }, children)) : (React.createElement("div", { className: textCls }, children));
    }
    return (React.createElement("div", __assign({}, others, { className: classNames("".concat(prefix, "radio-container"), "".concat(prefix, "radio-container--").concat(size), className), onClick: handleClick, "data-testid": "cn-radio-container" }),
        React.createElement("div", { className: circleCls },
            React.createElement("div", { className: pointCls })),
        text));
};
CnRadio.displayName = 'CnRadio';
CnRadio.defaultProps = {
    size: 'medium',
};
