import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { useValue } from '@/utils/use-value';
import './cn-checkbox.scss';
export var CnCheckbox = function (props) {
    var _a = props.size, size = _a === void 0 ? 'medium' : _a, _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, children = props.children, _checked = props.checked, _indeterminate = props.indeterminate, disabled = props.disabled, className = props.className, _defaultChecked = props.defaultChecked, _defaultIndeterminate = props.defaultIndeterminate, _c = props.checkIcon, checkIcon = _c === void 0 ? 'complete' : _c, _d = props.indeterminateIcon, indeterminateIcon = _d === void 0 ? 'minus' : _d, readOnly = props.readOnly, onChange = props.onChange, 
    /* notice: 对 group 进行容错 */
    value = props.value, label = props.label, text = props.text, others = __rest(props, ["size", "prefix", "children", "checked", "indeterminate", "disabled", "className", "defaultChecked", "defaultIndeterminate", "checkIcon", "indeterminateIcon", "readOnly", "onChange", "value", "label", "text"]);
    // 选中状态
    var _e = useValue(props, false, {
        valueName: 'checked',
    }), checked = _e[0], setChecked = _e[1], isCheckedControlled = _e[2];
    var _f = useValue(props, false, {
        valueName: 'indeterminate',
    }), indeterminate = _f[0], setIndeterminate = _f[1], isIndeterminateControlled = _f[2];
    // 点按状态
    var state = 'normal';
    var selectedPrefix = !indeterminate && checked ? '--selected' : '--unselected';
    var disabledPrefix = disabled ? '-disabled' : '';
    var indeterminatePrefix = indeterminate ? '-indeterminate' : '';
    var statePrefix = state !== 'normal' ? "-".concat(state) : '';
    var iconCls = classNames("".concat(prefix, "checkbox-icon"), "".concat(prefix, "checkbox-icon--").concat(size), "".concat(prefix, "checkbox-icon").concat(selectedPrefix).concat(indeterminatePrefix).concat(disabledPrefix));
    var textCls = classNames("".concat(prefix, "checkbox-text"), "".concat(prefix, "checkbox-text--").concat(size), "".concat(prefix, "checkbox-text").concat(selectedPrefix).concat(indeterminatePrefix).concat(disabledPrefix));
    var handleClick = React.useCallback(function (e) {
        if (disabled || readOnly) {
            return;
        }
        /**
         * 变化顺序是：
         * 半选 -> 全选
         * 全选 -> 不选
         * 不选 -> 全选
         */
        var newChecked = null;
        if (indeterminate) {
            if (isIndeterminateControlled) {
                if (onChange) {
                    onChange(!checked, e);
                }
                return;
            }
            else {
                setIndeterminate(false);
                newChecked = true;
            }
        }
        if (newChecked === null) {
            newChecked = !checked;
        }
        if (!isCheckedControlled) {
            setChecked(newChecked);
        }
        if (onChange) {
            onChange(newChecked, e);
        }
    }, [
        onChange,
        disabled,
        readOnly,
        checked,
        setChecked,
        isCheckedControlled,
        indeterminate,
        setIndeterminate,
        isIndeterminateControlled,
    ]);
    var renderChildren = function (c) {
        if (typeof c === 'string') {
            return React.createElement("span", { className: textCls }, children);
        }
        else if (typeof c !== 'undefined') {
            return React.createElement("div", { className: textCls }, children);
        }
        return null;
    };
    var iconSizeMap = {
        small: 'xxs',
        medium: 'xs',
        large: 'medium',
    };
    // 显示优先级：indeterminate > checked
    return (React.createElement("div", __assign({}, others, { className: classNames("".concat(prefix, "checkbox-container"), "".concat(prefix, "checkbox-container--").concat(size), "".concat(prefix, "checkbox-container--tag").concat(selectedPrefix), className), onClick: handleClick }),
        React.createElement("div", { className: classNames("".concat(prefix, "checkbox-circle"), "".concat(prefix, "checkbox-circle--").concat(size), "".concat(prefix, "checkbox-circle").concat(selectedPrefix).concat(indeterminatePrefix).concat(statePrefix).concat(disabledPrefix)) },
            indeterminate && (React.createElement(CnIcon, { type: indeterminateIcon, className: iconCls, size: iconSizeMap[size] })),
            checked && !indeterminate && (React.createElement(CnIcon, { type: checkIcon, className: iconCls, size: iconSizeMap[size] }))),
        renderChildren(children)));
};
CnCheckbox.displayName = 'CnCheckbox';
