import { __assign, __rest } from "tslib";
import React from 'react';
import { createFromIconfontCN } from './icon-font';
var CustomIcon = createFromIconfontCN({
    scriptUrl: 'https://at.alicdn.com/t/c/font_3267196_9f40m3jbp5i.js',
});
export var CnIcon = function (props) {
    var _a = props.type, type = _a === void 0 ? '' : _a, otherProps = __rest(props, ["type"]);
    var realType = type || '';
    if (!type.startsWith('icon-')) {
        realType = "icon-".concat(type);
    }
    return React.createElement(CustomIcon, __assign({ type: realType }, otherProps));
};
CnIcon.defaultProps = {
    size: 'medium',
};
CnIcon.displayName = 'CnIcon';
/**
 * @deprecated 建议直接从 cn-ui-m 中导出
 */
CnIcon.createFromIconfontCN = createFromIconfontCN;
