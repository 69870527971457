import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import './index.scss';
export var CnButton = function (props) {
    var _a;
    var children = props.children, onClick = props.onClick, className = props.className, component = props.component, type = props.type, text = props.text, inverse = props.inverse, fullWidth = props.fullWidth, htmlType = props.htmlType, loading = props.loading, disabled = props.disabled, size = props.size, warning = props.warning, href = props.href, target = props.target, others = __rest(props, ["children", "onClick", "className", "component", "type", "text", "inverse", "fullWidth", "htmlType", "loading", "disabled", "size", "warning", "href", "target"]);
    var fill = React.useMemo(function () {
        if (text) {
            return 'none';
        }
        if (type === 'primary') {
            return 'solid';
        }
        return 'outline';
    }, [type, text]);
    var color = React.useMemo(function () {
        if (inverse) {
            return 'inverse';
        }
        if (warning) {
            return 'warning';
        }
        if (type === 'primary' || (type === 'secondary' && !text)) {
            return 'primary';
        }
        return 'default';
    }, [warning, text, type, inverse]);
    var classes = cx(className, (_a = {
            'cn-ui-m-button': true
        },
        _a["cn-ui-m-button-".concat(color)] = !!color,
        _a["cn-ui-m-button-fill-".concat(fill)] = !!fill,
        _a["cn-ui-m-button-".concat(size)] = !!size,
        _a['cn-ui-m-button-loading'] = !!loading,
        _a['cn-ui-m-button-disabled'] = !!disabled,
        _a['cn-ui-m-button-fullwidth'] = !!fullWidth,
        _a));
    var TagName = component;
    var tagAttrs = {};
    if (TagName === 'button') {
        tagAttrs.type = htmlType;
    }
    else if (TagName === 'a') {
        tagAttrs.href = href;
        tagAttrs.target = target;
    }
    return (React.createElement(TagName, __assign({}, others, tagAttrs, { onClick: onClick, disabled: disabled, className: classes }),
        loading ? (React.createElement(CnIcon, { className: "cn-ui-m-button-loading-icon", type: "progressing", style: { display: 'block' } })) : null,
        children));
};
CnButton.displayName = 'CnButton';
CnButton.defaultProps = {
    type: 'normal',
    size: 'medium',
    fullWidth: false,
    component: 'button',
    htmlType: 'button',
    loading: false,
    warning: false,
    text: false,
    inverse: false,
    disabled: false,
};
