import { __assign, __rest } from "tslib";
import './index.scss';
import React from 'react';
import { CnUploadContext, defaultCnUploadRequestConfig, getRequestService, } from '@cainiaofe/cn-ui-common';
import { CnMessage } from '@/components/cn-message';
import $i18n from 'panda-i18n';
import { getErrorMsg } from '@cainiaofe/cn-ui-utils';
import { acceptList } from './constant/accept-list';
import { UploadView } from './components/upload';
export var OSSImageUpload = React.forwardRef(function (props, ref) {
    var service = props.service, requestConfig = props.requestConfig, onErrorProps = props.onError, mode = props.mode, restProps = __rest(props, ["service", "requestConfig", "onError", "mode"]);
    var fetchOSSToken = function (params) {
        var _a;
        var getRequestConfig = function () {
            // 标准逻辑
            if (requestConfig)
                return requestConfig;
            // start 兼容逻辑
            var _service = service;
            if (_service) {
                // 自定义函数
                if (typeof _service === 'function') {
                    return { service: _service };
                }
                // requestConfig 配置
                return _service;
            }
            // end 兼容逻辑
            return defaultCnUploadRequestConfig;
        };
        return (_a = getRequestService(getRequestConfig())) === null || _a === void 0 ? void 0 : _a(params);
    };
    /**
     * 组件内部主动通过 CnMessage.error 暴露错误内容
     */
    var onError = function (file) {
        var error = file.error;
        if (!error)
            return;
        var result = onErrorProps === null || onErrorProps === void 0 ? void 0 : onErrorProps(error, file);
        if (result === false)
            return;
        var msg = getErrorMsg(error, file.errorMsg ||
            $i18n.get({
                id: 'OSSCredentialAcquisitionFailed',
                dm: 'OSS凭证获取失败',
                ns: 'CnOssUpload',
            }));
        CnMessage.error({ content: msg });
    };
    return (React.createElement(CnUploadContext, __assign({}, restProps, { accept: acceptList.join(', '), onError: onError, fetchOSSToken: fetchOSSToken }),
        React.createElement(UploadView, __assign({ mode: mode, ref: ref }, restProps))));
});
OSSImageUpload.defaultProps = {
    headers: {},
    multiple: false,
    readOnly: false,
    showPreview: true,
    showRemove: true,
    openFileDialogOnClick: true,
};
OSSImageUpload.displayName = 'CnOSSImageUpload';
