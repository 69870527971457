import { __assign, __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n'; /* eslint-disable require-atomic-updates */
import locale from '@/locale';
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
import React, { Fragment } from 'react';
import { createForm, isForm } from '@formily/core';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import { toJS } from '@formily/reactive';
import { FormProvider, Observer, observer } from '@formily/react';
import { applyMiddleware, isBool, isFn, isNum, isStr, } from '@formily/shared';
// local component
import { CnDrawer } from '@/components/cn-drawer';
import { CnCard } from '@/components/cn-card';
import { SchemaField } from '@/form/schema-field';
import { createPortalRoot, loading } from '@/form/__builtins__';
import { request } from 'cn-request';
var isModalTitle = function (props) {
    return (isNum(props) || isStr(props) || isBool(props) || React.isValidElement(props));
};
var getModelProps = function (props) {
    if (isModalTitle(props)) {
        return { title: props };
    }
    return props;
};
$i18n.init({
    locale: locale,
    componentName: 'CnQuickDialogForm',
});
export function cnQuickDialogForm(cnDialogProps, renderer, 
/**
 * 点击提交后的请求配置
 */
requestConfig) {
    var _this = this;
    // 全局环境属性
    var env = {
        host: document.createElement('div'),
        form: null,
        promise: null,
        components: {},
        openMiddlewares: [],
        confirmMiddlewares: [],
        cancelMiddlewares: [],
    };
    var root = createPortalRoot(env.host, 'form-dialog');
    var dialogProps = getModelProps(cnDialogProps);
    var modal = __assign(__assign({}, dialogProps), { 
        // 移动端不存在center
        placement: dialogProps.placement === 'center' ? 'right' : dialogProps.placement });
    var DialogContent = observer(function () {
        return (React.createElement(Fragment, null, isFn(renderer) ? (renderer(env.form)) : (React.createElement(SchemaField, { components: env.components, schema: renderer }))));
    });
    // 渲染 Dialog 主题
    var renderDialog = function (visible, resolve, reject) {
        return (React.createElement(Observer, null, function () {
            var _a;
            return (React.createElement(CnDrawer, __assign({}, modal, { container: env.host, visible: visible, onClose: function (trigger, e) {
                    var _a;
                    (_a = modal === null || modal === void 0 ? void 0 : modal.onClose) === null || _a === void 0 ? void 0 : _a.call(modal, trigger, e);
                    // TODO: 目前Dialog没有afterClose能力，暂时用此办法实现
                    setTimeout(function () {
                        var _a;
                        (_a = dialogProps === null || dialogProps === void 0 ? void 0 : dialogProps.afterClose) === null || _a === void 0 ? void 0 : _a.call(dialogProps);
                        root.unmount();
                    }, 500);
                    reject && reject();
                }, onOk: function (e) {
                    var _a;
                    if (((_a = modal === null || modal === void 0 ? void 0 : modal.onOk) === null || _a === void 0 ? void 0 : _a.call(modal, e)) !== false) {
                        resolve && resolve();
                    }
                }, footer: true, okProps: __assign({ visible: true, loading: (_a = env.form) === null || _a === void 0 ? void 0 : _a.submitting, children: $i18n.get({
                        id: 'Confirm',
                        dm: '确认',
                        ns: 'CnQuickDialogForm',
                    }) }, modal.okProps), cancelProps: __assign({ visible: true, children: $i18n.get({
                        id: 'Cancel',
                        dm: '取消',
                        ns: 'CnQuickDialogForm',
                    }) }, modal.cancelProps), onCancel: function (e) {
                    var _a;
                    if (((_a = modal === null || modal === void 0 ? void 0 : modal.onCancel) === null || _a === void 0 ? void 0 : _a.call(modal, e)) !== false) {
                        reject && reject();
                    }
                } }),
                React.createElement(CnCard, null,
                    React.createElement(FormProvider, { form: env.form },
                        React.createElement(DialogContent, null)))));
        }));
    };
    document.body.appendChild(env.host);
    // 数据格式化处理
    function handleData(values) {
        // @ts-ignore
        if ((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.formatData) && isFn(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.formatData)) {
            // @ts-ignore
            return requestConfig.formatData(values);
        }
        else {
            return values;
        }
    }
    var formDialog = {
        registerComponents: function (components) {
            env.components = components;
            return formDialog;
        },
        forOpen: function (middleware) {
            if (isFn(middleware)) {
                env.openMiddlewares.push(middleware);
            }
            return formDialog;
        },
        forConfirm: function (middleware) {
            if (isFn(middleware)) {
                env.confirmMiddlewares.push(middleware);
            }
            return formDialog;
        },
        forCancel: function (middleware) {
            if (isFn(middleware)) {
                env.cancelMiddlewares.push(middleware);
            }
            return formDialog;
        },
        open: function (props) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (env.promise) {
                    return [2 /*return*/, env.promise];
                }
                env.promise = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                    var e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, loading('Loading', function () {
                                        return applyMiddleware(props, env.openMiddlewares);
                                    })];
                            case 1:
                                // forOpen 中间件植入
                                props = _a.sent();
                                if (!env.form) {
                                    if (isForm(props)) {
                                        env.form = props;
                                    }
                                    else {
                                        env.form = createForm(props);
                                    }
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                reject(e_1);
                                return [3 /*break*/, 3];
                            case 3:
                                root.render(function () {
                                    return renderDialog(true, function () {
                                        env.form
                                            .submit(function () { return __awaiter(_this, void 0, void 0, function () {
                                            var res;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!isPlainObject(requestConfig)) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, request(__assign(__assign({}, requestConfig), { data: toJS(handleData(env.form.values)) }))];
                                                    case 1:
                                                        res = _a.sent();
                                                        _a.label = 2;
                                                    case 2:
                                                        if (!isFunction(requestConfig)) return [3 /*break*/, 4];
                                                        return [4 /*yield*/, requestConfig(toJS(env.form.values))];
                                                    case 3:
                                                        res = _a.sent();
                                                        _a.label = 4;
                                                    case 4:
                                                        // 点击提交的中间件
                                                        env.form.response = res;
                                                        return [4 /*yield*/, applyMiddleware(env.form, env.confirmMiddlewares)];
                                                    case 5:
                                                        _a.sent();
                                                        resolve(toJS(env.form.values));
                                                        formDialog.close();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })
                                            .catch(function () { });
                                    }, function () { return __awaiter(_this, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, loading($i18n.get({
                                                        id: 'Loading',
                                                        dm: '加载中',
                                                        ns: 'CnQuickDialogForm',
                                                    }), function () {
                                                        return applyMiddleware(env.form, env.cancelMiddlewares);
                                                    })];
                                                case 1:
                                                    _a.sent();
                                                    formDialog.close();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                });
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/, env.promise];
            });
        }); },
        close: function () {
            if (!env.host)
                return;
            root.render(function () { return renderDialog(false); });
            env.promise = null;
        },
    };
    // @ts-ignore
    return formDialog;
}
