import $i18n from 'panda-i18n';
import React from 'react';
import { CnButtonGroup } from '@/components/cn-button-group';
import { CnButton } from '@/components/cn-button';
import { CnBox } from '@/components/cn-box';
import { CnCheckbox } from '@/components/cn-checkbox';
import './index.scss';
export var Toolbar = function (props) {
    var showSelect = props.showSelect, totalCount = props.totalCount, primaryKey = props.primaryKey, selectedKeys = props.selectedKeys, setSelectedKeys = props.setSelectedKeys, selectedDataSource = props.selectedDataSource, dataSource = props.dataSource, toolbarButtons = props.toolbarButtons, onSelectChange = props.onSelectChange;
    var showTotal = totalCount && totalCount !== 0;
    var showInfo = showSelect || showTotal;
    var showButtons = Boolean(toolbarButtons.length);
    var checked = Boolean(selectedKeys.size);
    var indeterminate = Boolean(selectedKeys.size && selectedKeys.size !== dataSource.length);
    return (React.createElement("div", { className: "cn-ui-m-card-items-toolbar" },
        showInfo ? (React.createElement("div", { className: "cn-ui-m-card-items-toolbar-info" },
            React.createElement(CnBox, { direction: "row", align: "center", spacing: 12 },
                showSelect ? (React.createElement(CnCheckbox, { className: "cn-ui-m-card-items-toolbar-info-select-all", size: "medium", checked: checked, indeterminate: indeterminate, onChange: function (v) {
                        if (v || indeterminate) {
                            var nextValue = new Set(dataSource.map(function (_) { return _[primaryKey]; }));
                            setSelectedKeys(nextValue);
                            typeof onSelectChange === 'function' &&
                                onSelectChange(nextValue);
                        }
                        else {
                            var nextValue = new Set();
                            setSelectedKeys(nextValue);
                            typeof onSelectChange === 'function' &&
                                onSelectChange(nextValue);
                        }
                    } }, $i18n.get({
                    id: 'SelectAll',
                    dm: '全选',
                    ns: 'CnCardItems',
                }))) : null,
                React.createElement("div", { className: "cn-ui-m-card-items-toolbar-info-total" },
                    showSelect ? (React.createElement("span", null, $i18n.get({
                        id: 'Selected',
                        dm: '已选',
                        ns: 'CnCardItems',
                    }))) : null,
                    showSelect ? (React.createElement("span", { className: "cn-ui-m-card-items-toolbar-info-select-count" }, selectedKeys.size)) : null,
                    showSelect ? (React.createElement("span", null, $i18n.get({
                        id: 'Article',
                        dm: '条',
                        ns: 'CnCardItems',
                    }))) : null,
                    showSelect && showTotal ? (React.createElement("span", { className: "cn-ui-m-card-items-toolbar-info-line" }, "/")) : null,
                    showTotal ? (React.createElement("span", null, $i18n.get({
                        id: 'ATotalOfTotalCount',
                        dm: '共{totalCount}条',
                        ns: 'CnCardItems',
                    }, { totalCount: totalCount }))) : null)))) : null,
        showInfo && showButtons ? (React.createElement("div", { className: "cn-ui-m-card-items-toolbar-space" })) : null,
        showButtons ? (React.createElement(CnButtonGroup, { className: "cn-ui-m-card-items-toolbar-buttons", fullWidth: true, isReverse: true, size: "large" }, toolbarButtons.map(function (button, i) {
            var onClick = button.onClick, disabled = button.disabled, children = button.children;
            return (React.createElement(CnButton, { key: "".concat(Date.now() + i), disabled: disabled, onClick: function (e) {
                    onClick === null || onClick === void 0 ? void 0 : onClick(e, Array.from(selectedKeys), selectedDataSource);
                } }, children));
        }))) : null));
};
