import { __assign } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import locale from '@/locale';
import React from 'react';
import { observer, RecursionField, useField, useFieldSchema, } from '@formily/react';
import { CnIcon } from '@/components/cn-icon';
import { CnCard } from '@/form/cn-card';
import cls from 'classnames';
import { ArrayBase } from '../array-base';
import './index.scss';
export var CnFormArrayCard = observer(function (props) {
    var autoIncrementTitle = props.autoIncrementTitle, additionText = props.additionText, hasDeleteButton = props.hasDeleteButton, defaultValue = props.defaultValue;
    var field = useField();
    var schema = useFieldSchema();
    var dataSource = Array.isArray(field.value) ? field.value : [];
    var defaultCardTitle = $i18n.get({
        id: 'Cards',
        dm: '卡片',
        ns: 'CnFormArrayCard',
    });
    return (React.createElement(ArrayBase, null,
        React.createElement("div", __assign({ "data-name": "CnFormArrayCard" }, props, { className: cls('cn-ui-m-form-array-card-container', props.className) }), dataSource === null || dataSource === void 0 ? void 0 :
            dataSource.map(function (item, index) {
                var items = Array.isArray(schema.items)
                    ? schema.items[index] || schema.items[0]
                    : schema.items;
                return (React.createElement(ArrayBase.Item, { key: index, index: index, record: function () { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(CnCard, { subTitle: "".concat(autoIncrementTitle || defaultCardTitle, " ").concat(index + 1), subAction: hasDeleteButton &&
                            field.editable && (React.createElement(CnIcon, { type: "icon-minus-r", onClick: function () { return field.remove(index); } })) },
                        React.createElement(RecursionField, { schema: items, name: index }))));
            }),
            React.createElement(ArrayBase.Addition, { title: additionText ||
                    $i18n.get({ id: 'New', dm: '新增', ns: 'CnFormArrayCard' }), defaultValue: defaultValue }))));
});
CnFormArrayCard.displayName = 'CnFormArrayCard';
CnFormArrayCard.defaultProps = {
    hasDeleteButton: true,
};
var i18nCnFormArrayCard = withI18n(CnFormArrayCard, { locale: locale });
ArrayBase.mixin(i18nCnFormArrayCard);
