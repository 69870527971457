import './index.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import { cnReadOnlyRender } from '@cainiaofe/cn-ui-common';
export var CnReadOnly = function (props) {
    var emptyRender = props.emptyRender, isOverflowEllipsis = props.isOverflowEllipsis;
    var value = props.children || props.value;
    var type = props.type || cnReadOnlyRender.getValueType(value);
    var classes = cx({
        'cn-ui-m-readonly': true,
        'cn-ui-m-readonly-ellipsis': isOverflowEllipsis,
    });
    var itemRender = function () {
        if (cnReadOnlyRender.isEmpty(value)) {
            return emptyRender();
        }
        if (type === 'string') {
            return cnReadOnlyRender.stringRender(value);
        }
        if (type === 'number') {
            return cnReadOnlyRender.numberRender(value);
        }
        if (type === 'boolean') {
            return cnReadOnlyRender.booleanRender({
                value: value,
                trueLabel: $i18n.get({ id: 'Yes', dm: '是', ns: 'CnReadOnly' }),
                falseLabel: $i18n.get({
                    id: '31255237809541120.CNTM',
                    dm: '否',
                    ns: 'CnReadOnly',
                }),
            });
        }
        return value;
    };
    return withNativeProps(props, React.createElement("div", { className: classes }, itemRender()));
};
CnReadOnly.displayName = 'CnReadOnly';
CnReadOnly.defaultProps = {
    isOverflowEllipsis: true,
    emptyRender: function () { return '- -'; },
};
/**
 * @deprecated 请使用 CnReadOnly 替换
 */
export var CnReadonly = CnReadOnly;
