import { __assign, __rest } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { CnButton } from '@/components/cn-button';
import locale from '@/locale';
$i18n.init({ locale: locale, componentName: 'CnSubmit' });
export var CnSubmit = function (_a) {
    var onSubmit = _a.onSubmit, onSubmitFailed = _a.onSubmitFailed, onSubmitSuccess = _a.onSubmitSuccess, form = _a.form, props = __rest(_a, ["onSubmit", "onSubmitFailed", "onSubmitSuccess", "form"]);
    return (React.createElement(CnButton, __assign({ type: "primary" }, props, { loading: props.loading !== undefined ? props.loading : form.submitting, onClick: function (e) {
            if (props.onClick) {
                if (props.onClick(e) === false)
                    return;
            }
            if (onSubmit) {
                form.submit(onSubmit).then(onSubmitSuccess).catch(onSubmitFailed);
            }
        } }), props.children ||
        $i18n.get({
            id: '31255239613091840.CNTM',
            dm: '提交',
            ns: 'CnSubmit',
        })));
};
