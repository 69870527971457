import { __assign, __rest } from "tslib";
import React, { forwardRef, useState, useEffect } from 'react';
var isDingTalk = (function () {
    try {
        return navigator.userAgent.toLocaleLowerCase().includes('dingtalk');
    }
    catch (_a) { }
    return false;
})();
function isClient() {
    return typeof window !== 'undefined' && typeof document !== 'undefined';
}
export function measureHeight() {
    if (!isClient())
        return null;
    if (isDingTalk)
        return null;
    return window.innerHeight;
}
export function use100vh() {
    var _a = useState(measureHeight), height = _a[0], setHeight = _a[1];
    useEffect(function () {
        if (isDingTalk)
            return;
        var setMeasuredHeight = function () {
            setHeight(measureHeight());
        };
        window.addEventListener('resize', setMeasuredHeight);
        return function () {
            window.removeEventListener('resize', setMeasuredHeight);
        };
    }, []);
    return height;
}
var Div100vh = forwardRef(function (_a, ref) {
    var style = _a.style, other = __rest(_a, ["style"]);
    var height = use100vh();
    var styleWithRealHeight = __assign(__assign({}, style), { height: height ? "".concat(height, "px") : '100vh' });
    return React.createElement("div", __assign({ ref: ref, style: styleWithRealHeight }, other));
});
Div100vh.displayName = 'Div100vh';
export default Div100vh;
