import * as React from 'react';
// 从children中找出快捷筛选的项
export var useSplitFilter = function (filter) {
    var _a = React.useState([null, null, null, null]), result = _a[0], setResult = _a[1];
    React.useEffect(function () {
        if (!filter) {
            setResult([null, null, null, null]);
            return;
        }
        var normalFilterChild = [];
        var searchChild = [];
        var quickFilterChild = [];
        var quickNoWrapFilterChild = [];
        filter.forEach(function (child) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (React.isValidElement(child)) {
                var childProps = (child.props || {});
                var isQuickFilter = childProps.quick || ((_a = childProps.mobileProps) === null || _a === void 0 ? void 0 : _a.quick);
                if (isQuickFilter) {
                    if (((_c = (_b = child.props.children) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.displayName) === 'CnSelector') {
                        quickNoWrapFilterChild.push(cnSelectorQuickItemRender(child));
                    }
                    else if (((_e = (_d = child.props.children) === null || _d === void 0 ? void 0 : _d.type) === null || _e === void 0 ? void 0 : _e.displayName) === 'CnCheckboxGroup') {
                        quickNoWrapFilterChild.push(cnCheckboxGroupQuickItemRender(child));
                    }
                    else if (((_g = (_f = child.props.children) === null || _f === void 0 ? void 0 : _f.type) === null || _g === void 0 ? void 0 : _g.displayName) === 'CnRadioGroup') {
                        quickNoWrapFilterChild.push(cnRadioGroupQuickItemRender(child));
                    }
                    else {
                        quickFilterChild.push(cnSelectQuickItemRender(child));
                    }
                }
                else if (((_j = (_h = child.props.children) === null || _h === void 0 ? void 0 : _h.type) === null || _j === void 0 ? void 0 : _j.displayName) === 'CnSearch') {
                    searchChild.push(cnSelectorQuickItemRender(child));
                }
                else {
                    normalFilterChild.push(child);
                }
            }
        });
        setResult([
            quickFilterChild.map(function (item, i) {
                var _a;
                return (React.createElement("div", { key: "".concat(Date.now() + i), className: "cn-ui-m-filter-quick-filter-wrapper" },
                    React.createElement("div", { className: "cn-ui-m-filter-quick-filter", style: (_a = item === null || item === void 0 ? void 0 : item.props) === null || _a === void 0 ? void 0 : _a.style }, item)));
            }),
            quickNoWrapFilterChild.map(function (item, i) {
                var _a;
                return (React.createElement("div", { key: "".concat(Date.now() + i), className: "cn-ui-m-filter-quick-filter-no-warp-wrapper" },
                    React.createElement("div", { className: "cn-ui-m-filter-quick-filter-no-warp", style: (_a = item === null || item === void 0 ? void 0 : item.props) === null || _a === void 0 ? void 0 : _a.style }, item)));
            }),
            normalFilterChild,
            searchChild.map(function (item, i) { return (React.createElement("div", { key: "".concat(Date.now() + i), className: "cn-ui-m-filter-search" }, item)); }),
        ]);
    }, [filter.length]);
    return result;
};
var cnSelectQuickItemRender = function (child) {
    // CnSelect 实例
    var itemChildren = child.props.children;
    // const newSelectField = React.useMemo(() => {
    return React.cloneElement(child, {
        name: child.props.name,
        decorator: [],
        children: React.cloneElement(itemChildren, {
            placeholder: child.props.label || child.props.title,
            insideFilter: true,
        }),
    });
    // }, [child, itemChildren]); // 依赖项
    // return newSelectField;
};
var cnSelectorQuickItemRender = function (child) {
    var itemChildren = child.props.children;
    return React.cloneElement(child, {
        name: child.props.name,
        decorator: [],
        children: React.cloneElement(itemChildren, {
            cnBoxProps: { wrap: false },
        }),
    });
};
var cnCheckboxGroupQuickItemRender = function (child) {
    var itemChildren = child.props.children;
    return React.cloneElement(child, {
        name: child.props.name,
        decorator: [],
        children: React.cloneElement(itemChildren, {
            shape: 'tag',
            direction: 'hoz',
        }),
    });
};
var cnRadioGroupQuickItemRender = function (child) {
    var itemChildren = child.props.children;
    return React.cloneElement(child, {
        name: child.props.name,
        decorator: [],
        children: React.cloneElement(itemChildren, {
            shape: 'tag',
            direction: 'hoz',
        }),
    });
};
