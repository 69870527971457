import { __assign, __awaiter, __generator, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { CnCascaderSelect, } from '@/components/cn-cascader-select';
import { getChildren, search } from './service';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import DepartmentValueItem from './department-value-item';
/** 一级部门code */
var TOP_DEPARTMENT_CODE = '22187';
var CnDepartmentSelect = function (props) {
    var 
    // 不支持外部传入的dataSource，排除掉
    propDataSource = props.dataSource, _a = props.mokelay, mokelay = _a === void 0 ? true : _a, host = props.host, path = props.path, others = __rest(props, ["dataSource", "mokelay", "host", "path"]);
    var _b = useState([]), dataSource = _b[0], setDataSource = _b[1];
    var _c = useState([]), searchResult = _c[0], setSearchResult = _c[1];
    var _d = useState(''), searchValue = _d[0], setSearchValue = _d[1];
    var timer = React.useRef();
    // 首次加载，查询一级部门
    useEffect(function () {
        var fetchInitData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getChildren({
                            value: TOP_DEPARTMENT_CODE,
                            mokelay: mokelay,
                            host: host,
                            path: path,
                        })];
                    case 1:
                        result = _a.sent();
                        setDataSource(result);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchInitData();
    }, [host, mokelay, path]);
    // 数据加载，根据父节点查询子部门
    var handleLoadData = useCallback(function (item) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getChildren({
                        value: item.value,
                        mokelay: mokelay,
                        host: host,
                        path: path,
                    })];
                case 1:
                    result = _a.sent();
                    setDataSource(function (cur) {
                        var clonedCur = cloneDeep(cur);
                        var getTail = function (i) {
                            if ((i === null || i === void 0 ? void 0 : i.children) && (i === null || i === void 0 ? void 0 : i.children.length) > 0) {
                                return i === null || i === void 0 ? void 0 : i.children.map(function (m) { return getTail(m); });
                            }
                            else {
                                return [i];
                            }
                        };
                        var childrenDepartment = find(flattenDeep(clonedCur.map(function (m) { return getTail(m); })), { code: item.value });
                        if (childrenDepartment) {
                            childrenDepartment.children = result;
                        }
                        return clonedCur;
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [host, mokelay, path]);
    var handleSearch = useCallback(function (v) {
        clearTimeout(timer.current);
        timer.current = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSearchValue(v);
                        return [4 /*yield*/, search({
                                value: v,
                                mokelay: mokelay,
                                host: host,
                                path: path,
                            })];
                    case 1:
                        res = _a.sent();
                        setSearchResult(res);
                        return [2 /*return*/];
                }
            });
        }); }, 300);
    }, [host, mokelay, path]);
    var handleVisibleChange = useCallback(function (visible) {
        if (visible) {
            setSearchResult([]);
            setSearchValue('');
        }
    }, []);
    return (React.createElement(CnCascaderSelect, __assign({ dataSource: searchValue ? searchResult : dataSource, loadData: handleLoadData, placeholder: $i18n.get({
            id: 'PleaseSelectADepartment',
            dm: '请选择部门',
            ns: 'CnDepartmentSelect',
        }), onSearch: handleSearch, onVisibleChange: handleVisibleChange, filterLocal: false, renderContent: function (values, separator) {
            return Array.isArray(values) && values.length > 0 ? (React.createElement(DepartmentValueItem, { items: values, separator: separator, mokelay: mokelay, host: host })) : ('');
        }, useSimpleValue: true }, others)));
};
CnDepartmentSelect.displayName = 'CnDepartmentSelect';
CnDepartmentSelect.defaultProps = {
    mokelay: true,
    showSearch: true,
};
export { CnDepartmentSelect };
