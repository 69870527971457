import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import isString from 'lodash/isString';
import './cn-step-item.scss';
/**
 * @category 组件
 * @description 步骤组件的子组件
 */
export var CnStepItem = function (props) {
    var _a, _b, _c, _d;
    var order = props.order, shape = props.shape, isFirst = props.isFirst, isLast = props.isLast, disabled = props.disabled, isPreDisabled = props.isPreDisabled, direction = props.direction, addon = props.addon, extra = props.extra, title = props.title, content = props.content, contentRender = props.contentRender, status = props.status, className = props.className, icon = props.icon, onClick = props.onClick, others = __rest(props, ["order", "shape", "isFirst", "isLast", "disabled", "isPreDisabled", "direction", "addon", "extra", "title", "content", "contentRender", "status", "className", "icon", "onClick"]);
    var clsPrefix = 'cn-ui-m-step-item';
    var isCircle = shape === 'circle';
    var classes = {
        item: classNames(clsPrefix, className, "".concat(clsPrefix, "--").concat(status), "".concat(clsPrefix, "--shape-").concat(shape), (_a = {},
            _a["".concat(clsPrefix, "--last")] = isLast,
            _a["".concat(clsPrefix, "--first")] = isFirst,
            _a["".concat(clsPrefix, "--disabled")] = disabled,
            _a["".concat(clsPrefix, "--pre-disabled")] = isPreDisabled,
            _a)),
        dot: classNames("".concat(clsPrefix, "-dot")),
        indicator: "".concat(clsPrefix, "-indicator"),
        hornLeft: classNames("".concat(clsPrefix, "-horn"), "".concat(clsPrefix, "-horn--left"), (_b = {},
            _b["".concat(clsPrefix, "-horn--hide")] = isFirst,
            _b)),
        hornRight: classNames("".concat(clsPrefix, "-horn"), "".concat(clsPrefix, "-horn--right"), (_c = {},
            _c["".concat(clsPrefix, "-horn--hide")] = isLast,
            _c)),
        node: classNames("".concat(clsPrefix, "-node")),
        addon: classNames("".concat(clsPrefix, "-addon"), (_d = {},
            _d["".concat(clsPrefix, "-addon--first")] = isFirst,
            _d)),
    };
    var renderCircleContent = function () {
        if (isCircle) {
            if (isString(icon) && icon !== '') {
                return React.createElement(CnIcon, { type: icon, size: "xs" });
            }
            else if (status === 'finish') {
                return React.createElement(CnIcon, { type: "complete", size: "xs" });
            }
            else {
                return (order || 0) + 1;
            }
        }
        else {
            return null;
        }
    };
    return (React.createElement("div", __assign({}, others, { className: classes.item, onClick: onClick, "data-testid": "cn-step-item" }),
        addon && direction === 'ver' ? (React.createElement("div", { className: classes.addon }, addon)) : null,
        React.createElement("div", { className: classes.indicator },
            React.createElement("div", { className: classes.hornLeft }),
            React.createElement("div", { className: classes.node },
                " ",
                renderCircleContent()),
            React.createElement("div", { className: classes.hornRight })),
        React.createElement("div", { className: "".concat(clsPrefix, "-body") },
            direction === 'hoz' && (React.createElement("div", { className: "".concat(clsPrefix, "-title") }, title)),
            direction === 'ver' && (React.createElement("div", { className: "".concat(clsPrefix, "-body-header") },
                React.createElement("div", { className: "".concat(clsPrefix, "-title") }, title),
                React.createElement("div", { className: "".concat(clsPrefix, "-extra") }, extra))),
            contentRender && contentRender(props, order || 0),
            (!contentRender && content) && React.createElement("div", { className: "".concat(clsPrefix, "-content") }, content))));
};
CnStepItem.displayName = 'CnStepItem';
CnStepItem.defaultProps = {
    isFirst: false,
    isLast: false,
    disabled: false,
    isPreDisabled: false,
};
