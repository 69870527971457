import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnBox } from '@/components/cn-box';
import { Indicator } from './indicator';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var prefix = 'cn-ui-m-';
/** Loading */
export var CnLoading = function (props) {
    var _a, _b, _c;
    var tip = props.tip, visible = props.visible, children = props.children, className = props.className, style = props.style, tipAlign = props.tipAlign, isGradient = props.isGradient, size = props.size, inverse = props.inverse, others = __rest(props, ["tip", "visible", "children", "className", "style", "tipAlign", "isGradient", "size", "inverse"]);
    var cls = classNames((_a = {
            'cn-ui-m-loading': true
        },
        _a["cn-ui-m-loading-".concat(size)] = true,
        _a["".concat(prefix, "open")] = visible,
        _a['cn-ui-m-loading-inverse'] = inverse,
        _a));
    var svgCls = classNames((_b = {
            'cn-load-svg': true
        },
        _b["cn-load-svg-".concat(size)] = true,
        _b));
    var indicatorDom = isGradient ? (React.createElement(Indicator, { className: svgCls, inverse: inverse })) : (React.createElement("div", { className: "cn-load" }));
    var spacing = React.useMemo(function () {
        var spacingNum = 8;
        switch (size) {
            case 'large':
                spacingNum = 12;
                break;
            case 'small':
                spacingNum = 4;
                break;
            default:
                break;
        }
        return spacingNum;
    }, [size]);
    var tipCls = classNames((_c = {},
        _c["".concat(prefix, "loading-tip")] = true,
        _c));
    // 不做遮罩，直接不渲染子节点
    if (visible) {
        return withNativeProps(props, React.createElement("div", __assign({ className: cls, style: style }, others),
            React.createElement(CnBox, { spacing: spacing, direction: tipAlign === 'right' ? 'row' : 'column', align: "center", justify: "center", className: tipCls },
                React.createElement("div", { className: "".concat(prefix, "loading-indicator") }, indicatorDom),
                React.createElement("div", { className: "".concat(prefix, "loading-tip-content") }, tip))));
    }
    return withNativeProps(props, React.createElement("div", __assign({ className: className, style: style }, others), children));
};
CnLoading.displayName = 'CnLoading';
CnLoading.defaultProps = {
    visible: true,
    animate: null,
    tipAlign: 'bottom',
    size: 'medium',
    disableScroll: false,
    isGradient: true,
};
