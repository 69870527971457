import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef, useCallback, useEffect, useState, } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { CnCalendar } from '@/components/cn-calendar';
import { SelectDrawer as CnSelectDrawer } from '@/components/cn-select/select-drawer';
import { CnReadOnly } from '@/components/cn-read-only';
import { CnDatePickerView } from './date-picker-view';
import isFunction from 'lodash/isFunction';
import { isValidDate, toDate } from '@/utils/date';
import { mergeProps } from '@/components/cn-picker/with-default-props';
import { useFormLayout } from '@/form/cn-form-layout';
import { formatValue, getFormatStrFromPrecision, getPrecisionFromFormat, getValueFromDayjs, } from './util';
import './date-picker.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var CnDatePicker = function (props, ref) {
    var defaultProps = {
        placeholder: $i18n.get({
            id: 'SelectDate',
            dm: '选择日期',
            ns: 'CnDatePickerPro',
        }),
        precision: 'day',
    };
    var wrapperAlign = useFormLayout().wrapperAlign;
    var _a = mergeProps(defaultProps, __assign(__assign({}, props), { precision: props.precision || getPrecisionFromFormat(props.format, props.showTime) })), _b = _a.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, valueProp = _a.value, defaultValue = _a.defaultValue, sizeProp = _a.size, alignProp = _a.align, iconType = _a.iconType, placeholder = _a.placeholder, format = _a.format, outputFormat = _a.outputFormat, hasClear = _a.hasClear, disabled = _a.disabled, readOnly = _a.readOnly, _c = _a.onChange, onChange = _c === void 0 ? function () { } : _c, _d = _a.onClear, onClear = _d === void 0 ? function () { } : _d, _e = _a.onCancel, onCancel = _e === void 0 ? function () { } : _e, className = _a.className, zIndex = _a.zIndex, precision = _a.precision, _f = _a.mode, mode = _f === void 0 ? 'picker' : _f, formLabel = _a.formLabel, insideFilter = _a.insideFilter, extraFooterRender = _a.extraFooterRender, others = __rest(_a, ["prefix", "value", "defaultValue", "size", "align", "iconType", "placeholder", "format", "outputFormat", "hasClear", "disabled", "readOnly", "onChange", "onClear", "onCancel", "className", "zIndex", "precision", "mode", "formLabel", "insideFilter", "extraFooterRender"]);
    var _g = useState(toDate(valueProp || defaultValue)), value = _g[0], setValue = _g[1];
    var _h = useState(value || new Date()), pickerValue = _h[0], setPickerValue = _h[1];
    var _j = useState(false), visible = _j[0], setVisible = _j[1];
    var align = alignProp || wrapperAlign || 'left';
    var size = sizeProp || 'medium';
    var isControlled = 'value' in props;
    useEffect(function () {
        if ('value' in props) {
            var newValue = toDate(valueProp || defaultValue);
            if (!isValidDate(newValue)) {
                setValue(undefined);
                return;
            }
            else if (newValue === value) {
                return;
            }
            setValue(newValue);
            setPickerValue(newValue);
        }
    }, [valueProp]);
    var handleCancel = function (reason) {
        setPickerValue(value || new Date());
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
    };
    var handleChange = function (d) {
        setPickerValue(d);
    };
    var handleClear = function () {
        if (!isControlled) {
            setPickerValue(new Date());
            setValue(undefined);
        }
        if (onClear) {
            onClear();
        }
        if (onChange) {
            onChange(undefined, '');
        }
    };
    var getFormated = useCallback(function (newValue) {
        var contentFormat = newValue && format && dayjs(newValue).format(format);
        var isWeekDay = precision === 'week-day';
        var contentWithprecision = newValue &&
            dayjs(newValue).format(getFormatStrFromPrecision(precision || 'day'));
        var contentWeek = newValue &&
            "".concat(newValue.getFullYear(), "\u5E74-").concat(dayjs(newValue).isoWeek(), "\u5468").concat(isWeekDay ? "-\u5468".concat(dayjs(newValue).isoWeekday()) : '');
        return (((precision === null || precision === void 0 ? void 0 : precision.includes('week'))
            ? contentWeek
            : contentFormat || contentWithprecision) || '');
    }, [value, format, precision]);
    var handleOk = useCallback(function () {
        var newValue = pickerValue;
        if (!isControlled) {
            setValue(newValue);
        }
        var processTime = !precision ||
            ['year', 'month', 'day', 'week', 'week-day'].includes(precision);
        var v = outputFormat
            ? formatValue(dayjs(newValue), outputFormat)
            : getValueFromDayjs(dayjs(newValue), processTime, props.endOfDay);
        onChange === null || onChange === void 0 ? void 0 : onChange(v, getFormated(newValue));
    }, [pickerValue, getFormated, props.endOfDay, precision, props.showTime]);
    // showTime 时的兼容逻辑，不必写 timePanelProps format
    var timePanelProps = props.timePanelProps;
    if (props.showTime && props.format && typeof props.format === 'string') {
        var timeFormat = props.format.trim().split(/\s+/)[1];
        if (timeFormat) {
            timePanelProps = __assign({}, timePanelProps) || {};
            timePanelProps.format = timeFormat;
        }
    }
    if (readOnly) {
        return React.createElement(CnReadOnly, { value: getFormated(value) });
    }
    var content = mode === 'calendar' ? (React.createElement(CnCalendar, __assign({}, others, { timePanelProps: timePanelProps, selectionMode: "single", value: pickerValue, onChange: function (val) {
            setPickerValue(val);
        } }))) : (React.createElement(CnDatePickerView, __assign({}, others, { prefix: prefix, precision: precision, visible: visible, value: pickerValue, onSelect: function (v) { return handleChange(v); } })));
    return withNativeProps(props, React.createElement(CnSelectDrawer, { disableScroll: true, hasClear: hasClear, content: getFormated(value), align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, insideForm: props.insideForm, className: classNames('cn-ui-m-date-picker-pro'), drawerClassName: "cn-ui-m-date-picker-pro-drawer", onVisibleChange: setVisible, zIndex: zIndex, onOk: handleOk, onCancel: handleCancel, onClear: handleClear, formLabel: formLabel, insideFilter: insideFilter, buttonPosition: "bottom", ref: ref },
        content,
        extraFooterRender ? (React.createElement("div", { className: "cn-ui-m-date-picker-extra-footer" }, typeof extraFooterRender === 'function'
            ? extraFooterRender()
            : extraFooterRender)) : null));
};
CnDatePicker.displayName = 'CnDatePickerPro';
export default forwardRef(CnDatePicker);
