import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import './cn-select-tag.scss';
/**
 * @category 组件
 * @description 选择用标签，为 CnSelector 的底层组件一般无需单独调用
 */
export var CnSelectTag = function (props) {
    var _a;
    var type = props.type, checked = props.checked, disabled = props.disabled, children = props.children, onClick = props.onClick;
    var classes = cx((_a = {
            'cn-ui-m-select-tag': true
        },
        _a["cn-ui-m-select-tag-type-".concat(type)] = !!type,
        _a['cn-ui-m-select-tag-checked'] = !!checked,
        _a['cn-ui-m-select-tag-disabled'] = !!disabled,
        _a));
    return withNativeProps(props, React.createElement("div", { onClick: onClick, className: classes }, children));
};
CnSelectTag.displayName = 'CnSelectTag';
CnSelectTag.defaultProps = {
    type: 'default',
    checked: false,
    disabled: false,
};
