import { __assign, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { useContext } from 'react';
import { CnList } from '@/components/cn-list';
import { CnButton } from '@/components/cn-button';
import isFunction from 'lodash/isFunction';
import { CnSelector } from '@/components/cn-selector';
import { findInArray, isNil, isValidArray, mergeDataSource, replaceMessage, } from '@/utils/func';
import SelectContext from '../context';
import Option from '../option';
import { defaultLocalFilterLowerCase, isSelected, removeItemFromArray } from '../util';
var sort = function (arr, value) {
    var selectedArr = [];
    var unSelectedArr = [];
    arr.forEach(function (item) {
        if (isSelected(value, item.value)) {
            selectedArr.push(item);
        }
        else {
            unSelectedArr.push(item);
        }
    });
    return __spreadArray(__spreadArray([], selectedArr, true), unSelectedArr, true);
};
var SelectBody = function (props) {
    var _a = useContext(SelectContext), prefix = _a.prefix, size = _a.size, displayType = _a.displayType, disabled = _a.disabled, mode = _a.mode, dataSource = _a.dataSource, _b = _a.filterLocal, filterLocal = _b === void 0 ? true : _b, _c = _a.stickyOnTop, stickyOnTop = _c === void 0 ? true : _c, _d = _a.filter, filter = _d === void 0 ? defaultLocalFilterLowerCase : _d, searchValue = _a.searchValue, innerValue = _a.innerValue, value = _a.value, transferSearchToOption = _a.transferSearchToOption, onChange = _a.onChange, onSearchAdd = _a.onSearchAdd, renderItem = _a.renderItem;
    var _e = props.searchToOptionBtnText, searchToOptionBtnText = _e === void 0 ? '' : _e, _f = props.emptySearchText, emptySearchText = _f === void 0 ? '' : _f;
    var renderWithTag = displayType === 'tag';
    var renderWithMenu = !renderWithTag && mode === 'single';
    var clsPrefix = "".concat(prefix, "select");
    // 执行排序
    var filteredDataSource = filterLocal
        ? mergeDataSource(dataSource, __spreadArray(__spreadArray([], value, true), innerValue, true)).filter(function (item) {
            return filter(searchValue, item);
        }) // 内部过滤 dataSource
        : dataSource;
    if (mode === 'multiple' && stickyOnTop) {
        filteredDataSource = sort(filteredDataSource, value);
    }
    var emptySearchContent = (React.createElement("div", { className: "".concat(clsPrefix, "-search-empty") },
        React.createElement("span", { className: "".concat(clsPrefix, "-search-empty-tip") }, searchValue
            ? replaceMessage(emptySearchText, { searchVal: searchValue })
            : $i18n.get({
                id: 'PleaseEnterKeywordSearch',
                dm: '请输入关键词搜索',
                ns: 'CnSelect',
            }))));
    // 这里原先是Menu实现，由于没有给选中的选项加className，导致无法定制样式，因此放弃掉，改用统一的Option
    if (renderWithMenu) {
        if (isValidArray(filteredDataSource)) {
            var singleOptions = filteredDataSource.map(function (it, index) { return (React.createElement(Option, __assign({ key: it.value + index, selected: isSelected(innerValue, it.value), disabled: disabled || it.disabled, multiple: false, onClick: function () {
                    var onClick = it.onClick;
                    if (isFunction(onClick)) {
                        onClick(it.value);
                    }
                    onChange([__assign({ label: it.label, value: it.value }, it)]);
                } }, it), typeof renderItem === 'function' ? renderItem(it) : it.label)); });
            return React.createElement(CnList, null, singleOptions);
        }
        return emptySearchContent;
    }
    if (renderWithTag) {
        return (React.createElement("div", { className: "".concat(prefix, "select--tag ").concat(prefix, "select--tag-").concat(size) },
            React.createElement(CnSelector, { prefix: prefix, mode: mode, value: innerValue.map(function (it) { return it.value; }), dataSource: filteredDataSource, onChange: function (v) {
                    if (isNil(v)) {
                        onChange([]);
                        return;
                    }
                    var newValue = Array.isArray(v) ? v : [v];
                    var newItems = newValue.map(function (x) {
                        return findInArray(filteredDataSource, function (item) { return item.value === x; });
                    });
                    onChange(newItems);
                } })));
    }
    var options = filteredDataSource.map(function (it) { return (React.createElement(Option, __assign({ key: it.value, selected: isSelected(innerValue, it.value), disabled: disabled || it.disabled, onClick: function () {
            var selectedItem = findInArray(innerValue, function (item) { return item.value === it.value; });
            if (selectedItem) {
                onChange(removeItemFromArray(innerValue, selectedItem.value));
            }
            else {
                onChange(__spreadArray(__spreadArray([], innerValue, true), [
                    __assign(__assign({}, it), { label: it.label, value: it.value }),
                ], false));
            }
        } }, it), typeof renderItem === 'function' ? renderItem(it) : it.label)); });
    // 常规渲染
    // eslint-disable-next-line no-nested-ternary
    return transferSearchToOption ? (React.createElement(CnList, null,
        searchValue &&
            !findInArray(filteredDataSource, function (it) { return "".concat(it.label) === "".concat(searchValue); }) && (React.createElement(Option
        // @ts-ignore
        , { 
            // @ts-ignore
            isNew: true, onClick: function () {
                if (onSearchAdd) {
                    onSearchAdd(searchValue, innerValue);
                }
                onChange(__spreadArray(__spreadArray([], innerValue, true), [
                    {
                        label: searchValue,
                        value: searchValue,
                        userAdd: true,
                    },
                ], false));
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                } },
                React.createElement("span", null, searchValue),
                React.createElement(CnButton, { size: "small", type: "secondary" }, searchToOptionBtnText)))),
        options)) : isValidArray(filteredDataSource) ? (React.createElement(CnList, null, options)) : (emptySearchContent);
};
export default SelectBody;
