import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnDatePickerPro, } from '@/components/cn-date-picker-pro';
var CnYearPickerPro = function (props) {
    return (React.createElement(CnDatePickerPro, __assign({ precision: "year", placeholder: $i18n.get({
            id: 'PleaseSelectTheYear',
            dm: '请选择年',
            ns: 'CnDatePickerPro',
        }), format: $i18n.get({
            id: 'YYYYYear',
            dm: 'YYYY年',
            ns: 'CnDatePickerPro',
        }) }, props)));
};
export { CnYearPickerPro };
