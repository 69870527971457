import { __assign, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import SelectDrawer from '@/components/cn-select/select-drawer/select-drawer';
import { CnTab, CnTabItem } from '@/components/cn-tab';
import { CnReadOnly } from '@/components/cn-read-only';
import { CascaderList } from './cascader-list';
import { calcSelectedList } from './utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import { useControlled } from '@cainiaofe/cn-ui-common';
var clsPrefix = 'cn-ui-m-cascader';
var separator = '/';
export var CnCascaderSelect = function (props) {
    var _a, _b;
    var dataSource = props.dataSource, drawerClassName = props.drawerClassName, hasClear = props.hasClear, _c = props.rootTipText, rootTipText = _c === void 0 ? $i18n.get({
        id: 'PleaseSelect',
        dm: '请选择',
        ns: 'CnCascaderSelect',
    }) : _c, isPreview = props.isPreview, readOnly = props.readOnly, _d = props.size, size = _d === void 0 ? 'medium' : _d, placeholder = props.placeholder, disabled = props.disabled, loadData = props.loadData, onSelect = props.onSelect, renderContent = props.renderContent, insideFilter = props.insideFilter;
    var _e = useControlled(props), value = _e[0], onChange = _e[1];
    var initSelectedList = useMemo(function () { return calcSelectedList(dataSource, value); }, [dataSource, value]);
    // 当用户在选择内容，未点击确定按钮的时候，内部保存状态，不透出给外部消费
    var _f = useState(initSelectedList), selectedList = _f[0], setSelectedList = _f[1];
    var _g = useState(true), showSelectedChildren = _g[0], setShowSelectedChildren = _g[1];
    var _h = useState(((_a = initSelectedList[initSelectedList.length - 1]) === null || _a === void 0 ? void 0 : _a.value) || 'placeholder'), selectedTabKey = _h[0], setSelectedTabKey = _h[1];
    var resetSelected = function () {
        var _a;
        setSelectedList(initSelectedList);
        setSelectedTabKey(((_a = initSelectedList[initSelectedList.length - 1]) === null || _a === void 0 ? void 0 : _a.value) || 'placeholder');
    };
    if (isPreview || readOnly) {
        return (React.createElement(CnReadOnly, { value: initSelectedList.map(function (node) { return node.label; }).join(separator) }));
    }
    /** 之所以从 dataSource 取数，是因为 dataSource 受控 */
    var getChildren = function (key) {
        if (!dataSource)
            return [];
        var temp = dataSource;
        if (!dataSource)
            return [];
        if (key === 'placeholder')
            return temp;
        var _loop_1 = function (item) {
            var findData = temp.find(function (_) { return _.value === item.value; });
            temp = (findData === null || findData === void 0 ? void 0 : findData.children) || [];
            if (key === item.value)
                return { value: temp };
        };
        for (var _i = 0, selectedList_1 = selectedList; _i < selectedList_1.length; _i++) {
            var item = selectedList_1[_i];
            var state_1 = _loop_1(item);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return [];
    };
    var list = selectedList.map(function (item) {
        return __assign(__assign({}, item), { children: getChildren(item.value) });
    });
    if (!list.length) {
        list.push({
            label: rootTipText,
            value: 'placeholder',
            children: dataSource,
        });
    }
    else {
        var len = list.length;
        var last = list[len - 1];
        if ((_b = last.children) === null || _b === void 0 ? void 0 : _b.length) {
            list.push({
                label: rootTipText,
                value: 'placeholder',
            });
        }
    }
    return withNativeProps(props, React.createElement(SelectDrawer, { placeholder: placeholder, disabled: disabled, hasClear: hasClear, size: size, className: classNames(clsPrefix), insideFilter: insideFilter, content: renderContent
            ? renderContent(initSelectedList, separator)
            : initSelectedList.map(function (item) { return item.label; }).join(separator), drawerClassName: classNames("".concat(clsPrefix, "-container"), drawerClassName), onOk: function () {
            var last = selectedList[selectedList.length - 1];
            onChange(last === null || last === void 0 ? void 0 : last.value, last);
        }, onCancel: resetSelected, onClear: function () { return onChange(undefined); }, buttonPosition: "bottom", "data-testid": "cn-cascader-select" },
        React.createElement(CnTab, { className: "".concat(clsPrefix, "-nav"), activeKey: "".concat(selectedTabKey, "_").concat(showSelectedChildren), contentClassName: "".concat(clsPrefix, "-content"), tabAlign: "left" }, list.map(function (item, i) {
            var children = item.children || [];
            var canSelect = true;
            if (!children.length || !showSelectedChildren) {
                var temp = list[i - 1];
                if (temp) {
                    children = temp.children || [];
                    canSelect = false;
                }
            }
            return (React.createElement(CnTabItem, { key: "".concat(item.value, "_").concat(showSelectedChildren), itemKey: item.value, title: item.label || rootTipText, onClick: function () {
                    if (item.value === 'placeholder')
                        return;
                    setShowSelectedChildren(false);
                    if (i === 0) {
                        setSelectedList([]);
                        setSelectedTabKey('placeholder');
                        return;
                    }
                    if (selectedTabKey === item.value)
                        return;
                    setSelectedList(__spreadArray([], selectedList.slice(0, i + 1), true));
                    setSelectedTabKey(item.value || 'placeholder');
                } },
                React.createElement(CascaderList, { options: children, value: item.value, loadData: loadData, onClick: function (subItem) {
                        if (canSelect) {
                            setSelectedList(__spreadArray(__spreadArray([], selectedList, true), [subItem], false));
                        }
                        else {
                            setSelectedList(__spreadArray(__spreadArray([], selectedList.slice(0, i), true), [subItem], false));
                        }
                        setShowSelectedChildren(true);
                        setSelectedTabKey(subItem.value);
                        onSelect === null || onSelect === void 0 ? void 0 : onSelect(subItem.value, subItem);
                    } })));
        }))));
};
CnCascaderSelect.displayName = 'CnCascaderSelect';
