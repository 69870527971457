import './styles/index.scss';
import locale from '@/locale';
import { withI18n } from 'panda-i18n';
import { CnDialog as CnDialogView } from './dialog';
import { CnPopup } from './popup';
import { alert, confirm, error, help, hide, notice, show, success, warning, } from './funcs';
export var CnDialog = withI18n(CnDialogView, {
    locale: locale,
});
CnDialog.show = show;
CnDialog.hide = hide;
CnDialog.alert = alert;
CnDialog.success = success;
CnDialog.error = error;
CnDialog.notice = notice;
CnDialog.warning = warning;
CnDialog.help = help;
CnDialog.confirm = confirm;
CnDialog.Popup = CnPopup;
export { CnPopup };
// 非要导出一个单纯的React.FC<CnDialogProps>才能生成文档，如果是继承类型CnDialogComponent文档出不来
export var CnDialogBase = CnDialogView;
