import { __assign } from "tslib";
import React, { useEffect, useState } from 'react';
import { renderToBody } from '@/components/cn-picker/render-to-body';
import { CnDatePickerPro, } from '@/components/cn-date-picker-pro';
export function prompt(props) {
    return new Promise(function (resolve) {
        var Wrapper = function () {
            var _a = useState(false), visible = _a[0], setVisible = _a[1];
            useEffect(function () {
                setVisible(true);
            }, []);
            return (React.createElement(CnDatePickerPro, __assign({}, props, { visible: visible, onConfirm: function (val) {
                    var _a;
                    (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props, val);
                    resolve(val);
                }, onClose: function () {
                    var _a;
                    (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
                    setVisible(false);
                    resolve(null);
                }, afterClose: function () {
                    var _a;
                    (_a = props.afterClose) === null || _a === void 0 ? void 0 : _a.call(props);
                    unmount();
                } })));
        };
        var unmount = renderToBody(React.createElement(Wrapper, null));
    });
}
