import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getDataSourceFromChildren } from '@/utils/func';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { getItemsFromDataSource } from '@/utils/tree';
import { useFormLayout } from '@/form/cn-form-layout';
import { CnReadOnly } from '@/components/cn-read-only';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { CnRadio } from './cn-radio';
import './cn-radio-group.scss';
var getInitValue = function (props) {
    var ret = [];
    if ('defaultValue' in props) {
        ret = props.defaultValue;
    }
    if ('value' in props) {
        ret = props.value;
    }
    return ret;
};
export var CnRadioGroup = function (props) {
    var _a;
    var size = props.size, direction = props.direction, children = props.children, groupDisabled = props.disabled, defaultValue = props.defaultValue, value = props.value, onChange = props.onChange, className = props.className, readOnly = props.readOnly, readOnlyRender = props.readOnlyRender, dataSource = props.dataSource, shape = props.shape, requestConfig = props.requestConfig, others = __rest(props, ["size", "direction", "children", "disabled", "defaultValue", "value", "onChange", "className", "readOnly", "readOnlyRender", "dataSource", "shape", "requestConfig"]);
    var data = useCnRequest(requestConfig).data;
    var prefix = 'cn-ui-m-';
    var _b = useState(getInitValue(props)), groupValue = _b[0], setGroupValue = _b[1];
    var isControlled = 'value' in props;
    var isButton = shape === 'button';
    var labelAlign = useFormLayout().labelAlign;
    var groupCls = classNames("".concat(prefix, "radio-group"), "".concat(prefix, "radio-group--").concat(labelAlign, "-").concat(size), className, (_a = {},
        _a["".concat(prefix, "radio-group-form")] = props.insideForm,
        _a["".concat(prefix, "radio-group--").concat(direction)] = !isButton,
        _a["".concat(prefix, "radio-button")] = isButton,
        _a["".concat(prefix, "radio-tag")] = shape === 'tag',
        _a));
    useEffect(function () {
        var newValue = getInitValue(props);
        if (isControlled) {
            setGroupValue(newValue);
        }
    }, [value, groupValue]);
    var handleChange = function (selected, radioValue, e, isDisabled) {
        if (!isDisabled && !readOnly) {
            if (!isControlled) {
                setGroupValue(radioValue);
            }
            // 新 radio 被选中时，处理 onChange 回调
            if (selected && isFunction(onChange)) {
                onChange(radioValue, e);
            }
        }
    };
    var content;
    var ds = dataSource || data;
    if (!ds && React.Children.count(children) > 0) {
        ds = getDataSourceFromChildren(children);
    }
    if (Array.isArray(ds)) {
        content = ds.map(function (item, index) {
            var _a, _b;
            var itemData = {
                style: {},
                value: null,
                checked: false,
                label: '',
                text: '',
                disabled: false,
                size: size,
            };
            var itemOnClick = item.onClick, itemOnChange = item.onChange, itemClassName = item.className, itemLabel = item.label, itemText = item.text, itemRest = __rest(item, ["onClick", "onChange", "className", "label", "text"]);
            if (isString(item) || isNumber(item)) {
                // @ts-ignore
                itemData.value = item;
            }
            else {
                itemData = __assign(__assign({}, itemData), itemRest);
            }
            var isDisabled = groupDisabled || itemData.disabled;
            var label = itemLabel || itemText || itemData.value;
            var isLast = index === ds.length - 1;
            var checked = itemData.value === groupValue;
            return (React.createElement(React.Fragment, null,
                React.createElement(CnRadio, __assign({}, itemData, { key: "radio_".concat(index), className: classNames(itemClassName, "".concat(prefix, "radio-item"), (_a = {},
                        _a["".concat(prefix, "radio-item--").concat(direction, "-").concat(size)] = !isLast,
                        _a["".concat(prefix, "radio-item--selected")] = checked,
                        _a["".concat(prefix, "radio-item--disabled")] = isDisabled,
                        _a)), disabled: isDisabled, checked: checked, onChange: function (selected, e) {
                        if (isFunction(itemOnChange)) {
                            itemOnChange(selected, e);
                        }
                        handleChange(selected, itemData.value, e, isDisabled);
                    }, onClick: itemOnClick }), label),
                isButton && !isLast ? (React.createElement("div", { className: classNames("".concat(prefix, "radio-item-separator"), (_b = {},
                        _b["".concat(prefix, "radio-item-separator--hidden")] = checked ||
                            (ds[index + 1] && ds[index + 1].value === groupValue),
                        _b)) })) : null));
        });
    }
    if (readOnly) {
        var arr = getItemsFromDataSource(ds, groupValue);
        var previewItem = {};
        if (Array.isArray(arr) && arr.length > 0) {
            previewItem = arr[0];
        }
        if (isFunction(readOnlyRender)) {
            return React.createElement("div", null, readOnlyRender(props));
        }
        return React.createElement(CnReadOnly, { value: previewItem.label || previewItem.text });
    }
    return (React.createElement("div", __assign({ className: groupCls }, others), content));
};
CnRadioGroup.displayName = 'CnRadioGroup';
CnRadioGroup.defaultProps = {
    size: 'medium',
    direction: 'hoz',
};
