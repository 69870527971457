import { __assign } from "tslib";
import React, { useLayoutEffect, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { UploadList } from './upload-list';
import { CnUploader, useUploadState, withNativeProps, } from '@cainiaofe/cn-ui-common';
export var UploadView = React.forwardRef(function (props, ref) {
    var mode = props.mode;
    var _a = useUploadState(), readOnly = _a.readOnly, limited = _a.limited;
    var comRef = useRef(null);
    var _b = useState(0), itemSize = _b[0], setItemSize = _b[1];
    var itemStyle = useMemo(function () {
        if (mode === 'single')
            return {};
        return { width: itemSize, height: itemSize };
    }, [itemSize, mode]);
    useLayoutEffect(function () {
        if (!comRef.current)
            return;
        if (mode === 'single')
            return;
        var rect = comRef.current.getBoundingClientRect();
        setItemSize(function (prev) {
            if (prev)
                return prev;
            var _itemSize = (rect.width - 12 * 3) / 4;
            if (_itemSize > 80)
                return 80;
            return _itemSize;
        });
    }, [mode]);
    var hideAddStyle = limited ? { display: 'none' } : {};
    return withNativeProps(props, React.createElement("div", { ref: comRef, className: "cn-ui-m-oss-image-upload-box", "data-testid": "cn-ui-m-oss-image-upload-box" },
        React.createElement("div", { className: classNames('cn-ui-m-oss-image-upload', {
                'cn-ui-m-oss-image-upload-readonly': readOnly,
                'cn-ui-m-oss-image-upload-single': mode === 'single',
            }) },
            React.createElement(UploadList, { style: itemStyle }),
            readOnly ? null : (React.createElement("div", { className: "cn-ui-m-oss-image-upload-item cn-ui-m-oss-image-upload-add", style: __assign(__assign({}, itemStyle), hideAddStyle) },
                React.createElement(CnUploader, { ref: ref, className: "cn-ui-m-oss-image-upload-add-inner" },
                    React.createElement(CnIcon, { type: "add", size: "xl" })))))));
});
