import { __assign, __awaiter, __generator, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { CnSelect } from '@/components/cn-select';
import { formatResponse, processDataSource, search } from './service';
import CnEmployeeItem from './employee-item';
import { useUpdateEffect } from 'ahooks';
import { isFunction } from 'lodash';
export var CnEmployeeSelect = function (props) {
    var _a = props.mokelay, mokelay = _a === void 0 ? true : _a, host = props.host, path = props.path, serviceType = props.serviceType, propDataSource = props.dataSource, typeList = props.typeList, isOnJob = props.isOnJob, isPreview = props.isPreview, readOnly = props.readOnly, _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, service = props.service, onChange = props.onChange, others = __rest(props, ["mokelay", "host", "path", "serviceType", "dataSource", "typeList", "isOnJob", "isPreview", "readOnly", "prefix", "service", "onChange"]);
    var _c = useState([]), dataSource = _c[0], setDataSource = _c[1];
    var timer = React.useRef();
    var serviceRef = React.useRef(service);
    var clsPrefix = "".concat(prefix, "employee-select");
    useUpdateEffect(function () {
        serviceRef.current = service;
    }, [service]);
    // value变化时查询具体员工信息
    useEffect(function () {
        var fetchInitData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var realValue, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        realValue = props.value;
                        try {
                            if (Array.isArray(props.value)) {
                                realValue = props.value.map(function (i) {
                                    if (i.value) {
                                        return i.value;
                                    }
                                    if (i.workNo) {
                                        return i.workNo;
                                    }
                                    return i;
                                }).join(',');
                            }
                        }
                        catch (e) {
                            console.log(e);
                        }
                        if (Object.prototype.toString.call(props.value) === '[object Object]') {
                            realValue = props.value.value || props.value.workNo;
                        }
                        return [4 /*yield*/, search({
                                value: realValue,
                                mokelay: mokelay,
                                host: host,
                                path: path,
                                serviceType: serviceType,
                                typeList: typeList,
                                isOnJob: isOnJob,
                                fromType: 'staffId',
                            })];
                    case 1:
                        result = _a.sent();
                        setDataSource(result);
                        return [2 /*return*/];
                }
            });
        }); };
        if (!props.value || !serviceType || propDataSource)
            return;
        fetchInitData();
    }, [
        props.value,
        host,
        mokelay,
        path,
        serviceType,
        typeList,
        isOnJob,
        propDataSource,
    ]);
    // 搜索逻辑
    var handleSearch = useCallback(function (val) { return __awaiter(void 0, void 0, void 0, function () {
        var temp, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!serviceRef.current) return [3 /*break*/, 2];
                    return [4 /*yield*/, serviceRef.current(val, 'keywords')];
                case 1:
                    temp = _a.sent();
                    result = formatResponse(temp);
                    setDataSource(result);
                    return [2 /*return*/];
                case 2:
                    if (!serviceType || propDataSource) {
                        return [2 /*return*/];
                    }
                    if (!val) {
                        setDataSource([]);
                        return [2 /*return*/];
                    }
                    clearTimeout(timer.current);
                    timer.current = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, search({
                                        value: val,
                                        mokelay: mokelay,
                                        host: host,
                                        path: path,
                                        serviceType: serviceType,
                                        typeList: typeList,
                                        isOnJob: isOnJob,
                                        fromType: 'keywords',
                                    })];
                                case 1:
                                    result = _a.sent();
                                    setDataSource(result);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 300);
                    return [2 /*return*/];
            }
        });
    }); }, [host, mokelay, serviceType, path, typeList, isOnJob, propDataSource]);
    // 当传入外部数据源时，自动格式化并赋值到组件
    useEffect(function () {
        if (!Array.isArray(propDataSource))
            return;
        setDataSource(processDataSource(propDataSource));
    }, [propDataSource]);
    return (React.createElement(CnSelect, __assign({ notFoundContent: $i18n.get({
            id: 'NoRelevantPersonnelDataHaveNot_1705518629',
            dm: '暂无未找到相关人员数据',
            ns: 'CnEmployeeSelect',
        }), placeholder: $i18n.get({
            id: 'PleaseEnterWorkNumberOrFlower_129025031',
            dm: '请输入工号或花名',
            ns: 'CnEmployeeSelect',
        }), onSearch: handleSearch, renderSelection: function (opts) {
            if (!opts.length)
                return null;
            return opts
                .map(function (i) { return i.label || i.nickName || i.name || i.value; })
                .join('，');
        }, renderPreview: function (values) {
            var list = values;
            if (props.mode === 'single') {
                if (!Array.isArray(values)) {
                    list = [values];
                }
            }
            return (React.createElement("div", { className: "".concat(clsPrefix, "-readonly") }, processDataSource(list).map(function (item) { return (React.createElement(CnEmployeeItem, { data: item, mini: true })); })));
        }, isPreview: readOnly || isPreview, filterLocal: false, showSearch: true, dataSource: dataSource, renderItem: function (item) { return React.createElement(CnEmployeeItem, { data: item }); }, onChange: function (value, actionType, items) {
            if (!isFunction(onChange))
                return;
            if (props.useDetailValue) {
                var valueItem = dataSource.find(function (i) { return i.value === value.value; }) || value;
                onChange(valueItem, actionType, items);
            }
            else {
                onChange(value, actionType, items);
            }
        } }, others)));
};
CnEmployeeSelect.displayName = 'CnEmployeeSelect';
CnEmployeeSelect.defaultProps = {
    mokelay: true,
    typeList: ['REGULAR', 'OUTSOURCING'],
    isOnJob: true,
};
