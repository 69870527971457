import { __assign, __rest } from "tslib";
import React from 'react';
import cx from 'classnames';
import Paragraph from './paragraph';
import Title from './title';
// @ts-ignore
export var CnSkeleton = function (props) {
    var className = props.className, others = __rest(props, ["className"]);
    return (React.createElement("div", __assign({ className: cx(className, 'cn-ui-m-skeleton') }, others), props.children));
};
CnSkeleton.Title = Title;
CnSkeleton.Paragraph = Paragraph;
