import React from 'react';
import { useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from './upload-list-item';
export var UploadList = function (props) {
    var getValue = useUploadState().getValue;
    var fileList = getValue();
    if (!fileList.length)
        return null;
    return (React.createElement(React.Fragment, null, fileList.map(function (item, index) { return (React.createElement(UploadListItem, { key: item.key || "".concat(item.name, "_").concat(index), index: index, fileList: fileList, file: item, style: props.style })); })));
};
