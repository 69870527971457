import { __assign, __rest } from "tslib";
import React from 'react';
import { isVoidField } from '@formily/core';
import { connect, mapProps, useField } from '@formily/react';
import { useFormLayout } from '@/form/cn-form-layout';
import { CnFormItem as UiCnFormItem, } from '@/components/cn-form-item';
import { topLayoutList } from './constant/top-layout-list';
export var CnFormItem = connect(function (props) {
    var _a, _b, _c, _d;
    var children = props.children, restProps = __rest(props, ["children"]);
    var triggerRef = React.useRef();
    var layout = useFormLayout();
    // 源码，获取组件名
    var componentsName = props === null || props === void 0 ? void 0 : props.triggerName;
    try {
        // 低码，获取组件名
        var field = useField();
        if ((field === null || field === void 0 ? void 0 : field.component) &&
            (field === null || field === void 0 ? void 0 : field.component[0]) &&
            typeof (field === null || field === void 0 ? void 0 : field.component[0]) === 'string') {
            componentsName = field === null || field === void 0 ? void 0 : field.component[0];
        }
    }
    catch (_e) { }
    var labelAlign = (_a = props.labelAlign) !== null && _a !== void 0 ? _a : layout.labelAlign;
    if (topLayoutList.includes(componentsName)) {
        labelAlign = 'top';
    }
    return (React.createElement(UiCnFormItem, __assign({ triggerRef: triggerRef }, restProps, { labelAlign: labelAlign, labelWidth: (_b = props.labelWidth) !== null && _b !== void 0 ? _b : layout.labelWidth, labelTextAlign: (_c = props.labelTextAlign) !== null && _c !== void 0 ? _c : layout.labelTextAlign, wrapperAlign: (_d = props.wrapperAlign) !== null && _d !== void 0 ? _d : layout.wrapperAlign }), React.isValidElement(children)
        ? React.cloneElement(children, {
            ref: triggerRef,
            insideForm: true,
            formLabel: props.title,
            style: props.fullWidth ? { width: '100%' } : null,
        })
        : children));
}, mapProps(function (props, field) {
    if (isVoidField(field)) {
        return {
            readOnly: field.readOnly,
            label: field.title || props.label,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    var takeFeedbackStatus = function () {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    // 获取错误信息
    var takeMessage = function () {
        var split = function (messages) {
            return messages.reduce(function (buf, text) {
                if (!text)
                    return buf;
                return buf.concat(text);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                return [props.requiredMessage];
            }
            else {
                return split(field.selfErrors);
            }
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    // 获取是否有 required mark
    var takeAsterisk = function () {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        title: props.label || field.title,
        validateState: takeFeedbackStatus(),
        errors: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
        readOnly: field.readOnly,
        disabled: field.disabled,
    };
}));
