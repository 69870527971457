import { __assign } from "tslib";
import React, { forwardRef } from 'react';
import { CnLoading } from '@/components/cn-loading';
import { useStyleOverrides } from '../../hooks/use-style-overrides';
var More = forwardRef(function (props, ref) {
    var moreRender = props.moreRender, style = props.style;
    var overrides = useStyleOverrides(props, 'scroll-view-more');
    var styledScrollViewMore = __assign({ display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'font-size': 'var(--s-3)', color: 'var(--scroll-more-color)' }, style);
    return (React.createElement("div", __assign({}, props, overrides, { style: styledScrollViewMore, ref: ref }), moreRender || React.createElement(CnLoading, { visible: true, size: "small" })));
});
export default More;
