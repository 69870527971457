import * as React from 'react';
import classNames from 'classnames';
import { CnPageFooter } from '@/components/cn-page-footer';
import { withNativeProps } from '@/utils/with-native-props';
import { getComponentName } from '@/utils/get-component-name';
import { getWindowData } from '@/utils/get-window-data';
import Div100vh from './height';
function autoAddPageLayout() {
    try {
        document.documentElement.classList.add('cn-ui-m-page-layout');
    }
    catch (_a) { }
}
/**
 * @category 组件
 * @description 页面容器
 */
export var CnPage = function (props) {
    autoAddPageLayout();
    var children = props.children, noPadding = props.noPadding, _a = props.footer, footerProps = _a === void 0 ? null : _a;
    var footer = React.useMemo(function () {
        if (!footerProps)
            return null;
        if (getComponentName(footerProps) === 'CnPageFooter')
            return footerProps;
        return React.createElement(CnPageFooter, null, footerProps);
    }, [footerProps]);
    var hasCnTab = React.useMemo(function () {
        return React.Children.toArray(children).some(function (child) {
            return getComponentName(child) === 'CnTab';
        });
    }, [children]);
    // @ts-ignore 忽略 data 属性校验，内部使用
    var hideHeader = props['data-hideHeader'];
    return (React.createElement(Div100vh, { className: "cn-ui-m-page" },
        props.header,
        withNativeProps(props, React.createElement("div", { className: "cn-ui-m-page-body", "data-testid": "cn-page-body" },
            hideHeader ? null : getWindowData('__CNUI_shellHeader') || null,
            React.createElement("div", { className: classNames('cn-ui-m-page-content', {
                    'cn-ui-m-page-content-with-padding': !noPadding && !hasCnTab,
                }) }, children))),
        footer,
        React.createElement("div", { className: "cn-ui-m-page-footer-placeholder" })));
};
