import { __assign } from "tslib";
import React from 'react';
import { CnReadOnly } from '@/components/cn-read-only';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import BaseSelect from './base-select';
import { getPreviewValue } from './util';
var CnSelect = function (props, ref) {
    var isPreview = props.isPreview, readOnly = props.readOnly, previewClassNameProp = props.previewClassName, previewStyle = props.previewStyle, renderPreview = props.renderPreview, _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, _b = props.size, size = _b === void 0 ? 'medium' : _b;
    // notice: if mode=single, will return a non-array value
    var values = getPreviewValue(props);
    var previewClassName = classNames(previewClassNameProp, "".concat(prefix, "select-preview--").concat(size));
    // render preview element
    if (isPreview || readOnly) {
        if (isFunction(renderPreview)) {
            return (React.createElement("div", { className: previewClassName, style: previewStyle }, renderPreview(values, props)));
        }
        var directRenderVal = '';
        if (Array.isArray(values)) {
            directRenderVal = values.map(function (v) { return v.label; }).join(',');
        }
        else if (values && values.label) {
            directRenderVal = values.label;
        }
        return (React.createElement("div", { className: previewClassName, style: previewStyle },
            React.createElement(CnReadOnly, null, directRenderVal)));
    }
    return React.createElement(BaseSelect, __assign({}, props, { ref: ref }));
};
CnSelect.displayName = 'CnSelect';
export default React.forwardRef(CnSelect);
