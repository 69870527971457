import { __assign, __rest } from "tslib";
import React from 'react';
import { renderToRoot } from '../../utils/render';
import { CnMessage } from './message';
import isString from 'lodash/isString';
var clsPrefix = 'cn-ui-m-message';
var hideCurrent = null;
export var hide = function () {
    if (hideCurrent) {
        hideCurrent();
    }
    hideCurrent = null;
};
export var show = function (options) {
    var _a = options.shape, shape = _a === void 0 ? 'toast' : _a, _b = options.duration, duration = _b === void 0 ? 3000 : _b, content = options.content, type = options.type, onClose = options.onClose, showMask = options.showMask, other = __rest(options, ["shape", "duration", "content", "type", "onClose", "showMask"]);
    hide();
    /* 默认loading 带mask,其他不带，支持mask配置 */
    var handleShowMask = function () {
        var isShowMask = typeof (showMask) === 'boolean' ? showMask : type === 'loading';
        return isShowMask;
    };
    var isShowMask = handleShowMask();
    var hideThis = renderToRoot(React.createElement("div", null,
        React.createElement(CnMessage, __assign({ visible: true, shape: shape, type: type }, other), content),
        isShowMask && React.createElement("div", { className: "".concat(clsPrefix, "-mask") })), onClose);
    hideCurrent = hideThis;
    if (duration > 0) {
        if (type === 'loading' && !('duration' in options)) {
            return hideThis;
        }
        setTimeout(function () {
            hideThis();
            if (hideCurrent === hideThis) {
                hideCurrent = null;
            }
        }, duration);
    }
    return hideThis;
};
var handleFastCall = function (type, options) {
    var opts;
    if (isString(options)) {
        opts = {
            title: options,
        };
    }
    else {
        opts = options;
    }
    return show(__assign(__assign({}, opts), { type: type }));
};
export var success = handleFastCall.bind(null, 'success');
export var error = handleFastCall.bind(null, 'error');
export var notice = handleFastCall.bind(null, 'notice');
export var warning = handleFastCall.bind(null, 'warning');
export var help = handleFastCall.bind(null, 'help');
export var loading = handleFastCall.bind(null, 'loading');
