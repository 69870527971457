import { __assign, __rest } from "tslib";
import React, { forwardRef, useEffect, useState, } from 'react';
import dayjs from 'dayjs';
import { SelectDrawer as CnSelectDrawer } from '@/components/cn-select/select-drawer';
import { CnEmbedPicker } from './embed-picker';
import isFunction from 'lodash/isFunction';
import { isValidDate, toDate } from '@/utils/date';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var TimePicker = function (props, ref) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, valueProp = props.value, defaultValue = props.defaultValue, sizeProp = props.size, alignProp = props.align, iconType = props.iconType, placeholder = props.placeholder, format = props.format, hasClear = props.hasClear, disabled = props.disabled, outputFormat = props.outputFormat, readOnly = props.readOnly, _b = props.onChange, onChange = _b === void 0 ? function () { } : _b, _c = props.onClear, onClear = _c === void 0 ? function () { } : _c, _d = props.onCancel, onCancel = _d === void 0 ? function () { } : _d, className = props.className, zIndex = props.zIndex, others = __rest(props, ["prefix", "value", "defaultValue", "size", "align", "iconType", "placeholder", "format", "hasClear", "disabled", "outputFormat", "readOnly", "onChange", "onClear", "onCancel", "className", "zIndex"]);
    var _e = useState(toDate(valueProp || defaultValue)), value = _e[0], setValue = _e[1];
    var _f = useState(value || new Date()), pickerValue = _f[0], setPickerValue = _f[1];
    var _g = useState(false), visible = _g[0], setVisible = _g[1];
    var align = alignProp || 'left';
    var size = sizeProp || 'medium';
    var isControlled = 'value' in props;
    var handleOk = function () {
        var newValue = pickerValue;
        if (onChange) {
            onChange(outputFormat
                ? dayjs(newValue).format(outputFormat)
                : newValue.getTime(), dayjs(newValue).format('HH:mm:ss'));
        }
        if (!isControlled) {
            setValue(newValue);
        }
    };
    useEffect(function () {
        if ('value' in props) {
            var newValue = toDate(valueProp || defaultValue);
            if (!isValidDate(newValue)) {
                setValue(undefined);
                return;
            }
            else if (newValue === value) {
                return;
            }
            setValue(newValue);
            setPickerValue(newValue);
        }
    }, [valueProp]);
    var handleCancel = function (reason) {
        setPickerValue(value || new Date());
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
    };
    var handleChange = function (d) { return setPickerValue(d); };
    var handleClear = function () {
        if (!isControlled) {
            setPickerValue(new Date());
            setValue(undefined);
        }
        if (onClear) {
            onClear();
        }
        if (onChange) {
            onChange(undefined, '');
        }
    };
    return withNativeProps(props, React.createElement(CnSelectDrawer, { disableScroll: true, hasClear: hasClear, content: value ? dayjs(value).format(format || 'HH:mm') : '', align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, className: className, insideForm: props.insideForm, onVisibleChange: setVisible, zIndex: zIndex, onOk: handleOk, onCancel: handleCancel, onClear: handleClear, ref: ref },
        React.createElement(CnEmbedPicker, __assign({}, others, { prefix: prefix, visible: visible, value: pickerValue, onCancel: handleCancel, format: format || 'HH:mm', onChange: function (v) {
                if (v === void 0) { v = new Date(); }
                return handleChange(v);
            } }))));
};
TimePicker.displayName = 'CnTimePicker';
var CnTimePicker = forwardRef(TimePicker);
CnTimePicker.displayName = 'CnTimePickerPro';
export { CnTimePicker };
