import React from 'react';
import { withNativeProps } from '@/utils/with-native-props';
import { CnIcon } from '@/components/cn-icon';
import classNames from 'classnames';
var classPrefix = 'cn-ui-m-nav-bar';
var CnNavBar = function (props) {
    var back = props.back, _a = props.backArrow, backArrow = _a === void 0 ? true : _a, enableSafeArea = props.enableSafeArea;
    var content = withNativeProps(props, React.createElement("div", { className: classNames(classPrefix), "data-testid": "cn-nav-bar" },
        React.createElement("div", { className: "".concat(classPrefix, "-left"), role: "button" },
            back !== null && (React.createElement("div", { className: "".concat(classPrefix, "-back"), onClick: props.onBack, "data-testid": "nav-bar-back-button" },
                backArrow && (React.createElement("span", { className: "".concat(classPrefix, "-back-arrow") }, backArrow === true ? (React.createElement(CnIcon, { type: "arrow-left", size: "xl" })) : (backArrow))),
                React.createElement("span", { "aria-hidden": "true" }, back))),
            props.left ? (React.createElement("div", { className: "".concat(classPrefix, "-left-content") }, props.left)) : null),
        React.createElement("div", { className: "".concat(classPrefix, "-title-container") },
            props.title ? (React.createElement("div", { className: "".concat(classPrefix, "-title") }, props.title)) : null,
            props.subTitle ? (React.createElement("div", { className: "".concat(classPrefix, "-subtitle") }, props.subTitle)) : null),
        React.createElement("div", { className: "".concat(classPrefix, "-right") }, props.right)));
    return (React.createElement("div", { className: classNames("".concat(classPrefix, "-wrapper")) },
        enableSafeArea ? (React.createElement("div", { className: classNames("".concat(classPrefix, "-safe-area")) })) : null,
        content));
};
CnNavBar.displayName = 'CnNavBar';
CnNavBar.defaultProps = {
    backArrow: true,
};
export { CnNavBar };
