import React, { useContext } from 'react';
import { CnResult } from '@/components/cn-result';
import { CnCard } from '@/components/cn-card';
import { CnLoading } from '@/components/cn-loading';
import { CardItemsStateCtx } from '../../context/card-items';
import { LoadMore } from '../load-more';
import { Item } from '../item';
import './index.scss';
/** 卡片列表 */
export var List = function () {
    var _a = useContext(CardItemsStateCtx), loading = _a.loading, dataSource = _a.dataSource, primaryKey = _a.primaryKey;
    if (!loading && !(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)) {
        return (React.createElement(CnCard, { className: "cn-ui-m-card-items-empty" },
            React.createElement(CnResult, { type: "empty" })));
    }
    if (loading) {
        return (React.createElement(CnCard, null,
            React.createElement(CnLoading, { visible: true, size: "large", className: "cn-ui-m-card-items-loading" })));
    }
    return (React.createElement("div", { className: "cn-ui-m-card-items" }, dataSource === null || dataSource === void 0 ? void 0 :
        dataSource.map(function (item, i) { return (React.createElement(Item, { key: primaryKey && item[primaryKey] ? String(item[primaryKey]) : i, rowIndex: i, data: item })); }),
        React.createElement(LoadMore, null)));
};
