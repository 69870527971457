import * as React from 'react';
export var getComponentName = function (component) {
    if (!React.isValidElement(component))
        return undefined;
    try {
        var type = component.type;
        return type.displayName || type.name || undefined;
    }
    catch (_a) { }
    return undefined;
};
