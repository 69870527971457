import { __assign, __extends, __rest } from "tslib";
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { CnActionSheet } from './action-sheet';
var ActionSheetWrapper = /** @class */ (function (_super) {
    __extends(ActionSheetWrapper, _super);
    function ActionSheetWrapper(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function (option, index, e) {
            if (_this.props.onClick) {
                _this.props.onClick(option, index, e);
            }
        };
        _this.handleClose = function (reason) {
            _this.setState({
                visible: false,
            });
            if (_this.props.onClose) {
                _this.props.onClose(reason);
            }
        };
        _this.state = {
            visible: true,
        };
        return _this;
    }
    ActionSheetWrapper.prototype.render = function () {
        var _a = this.props, message = _a.message, options = _a.options, others = __rest(_a, ["message", "options"]);
        var visible = this.state.visible;
        return (React.createElement(CnActionSheet, __assign({ visible: visible }, others, { message: message, options: options, onClose: this.handleClose, onClick: this.handleClick })));
    };
    ActionSheetWrapper.defaultProps = {
        message: '',
        options: [],
        onClick: function () { },
        onClose: function () { },
    };
    return ActionSheetWrapper;
}(Component));
function renderElement(element, onHide) {
    var root = document.createElement('div');
    var hid = false;
    var hide = function () {
        if (hid) {
            return;
        }
        try {
            hid = true;
            ReactDOM.unmountComponentAtNode(root);
            document.body.removeChild(root);
            if (onHide) {
                onHide();
            }
        }
        catch (e) {
            throw new Error('remove element error');
        }
    };
    document.body.appendChild(root);
    ReactDOM.render(element, root);
    return hide;
}
/**
 * 显示 ActionSheet
 * @param {Object} options
 */
export default function (options) {
    var onClose = options.onClose, others = __rest(options, ["onClose"]);
    var hide = function () { };
    var handleClose = function (v) {
        if (typeof onClose === 'function') {
            onClose(v);
        }
        setTimeout(function () {
            hide();
        }, 300);
    };
    hide = renderElement(React.createElement(ActionSheetWrapper, __assign({}, others, { onClose: handleClose })));
    return {
        close: function () { return hide(); },
    };
}
