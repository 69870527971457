import { __assign, __awaiter, __generator, __rest, __spreadArray } from "tslib";
import { useMemo, useRef } from 'react';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
export function useFetchDataService(props) {
    var _this = this;
    var currentPageRef = useRef(1);
    var dataSourceMapRef = useRef({});
    // @ts-ignore instance属性属于历史兼容逻辑
    var _a = props.requestConfig || {}, instance = _a.instance, requestConfig = __rest(_a, ["instance"]);
    var hasRequestConfig = Boolean(props.requestConfig);
    var config = useMemo(function () {
        var _a, _b;
        if (!requestConfig)
            return {};
        // 解决首次加载分页参数丢失问题
        if (((_a = requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === 'GET') {
            requestConfig.params = __assign({ currentPage: (currentPageRef === null || currentPageRef === void 0 ? void 0 : currentPageRef.current) || 1, pageSize: 10 }, requestConfig.params);
        }
        else if (!(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method) || ((_b = requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === 'POST') {
            requestConfig.data = __assign({ currentPage: (currentPageRef === null || currentPageRef === void 0 ? void 0 : currentPageRef.current) || 1, pageSize: 10 }, requestConfig.data);
        }
        return __assign(__assign({ defaultParams: {
                currentPage: 1,
                pageSize: 10,
            } }, requestConfig), { method: (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method) || 'POST' });
    }, [requestConfig]);
    var _b = useCnRequest(config), data = _b.data, runAsync = _b.runAsync, loading = _b.loading;
    return useMemo(function () {
        var _a;
        if (!hasRequestConfig) {
            var refreshAsync_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            var currentPageRefreshAsync_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            var loadNextPage_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            // @ts-ignore instance属性属于历史兼容逻辑,该逻辑后期会下线 start
            if (instance === null || instance === void 0 ? void 0 : instance.ref) {
                instance.ref.current.refresh = refreshAsync_1;
                instance.ref.current.load = currentPageRefreshAsync_1;
            }
            // end
            return {
                loading: !!hasRequestConfig,
                hasNextPage: false,
                refreshAsync: refreshAsync_1,
                currentPageRefreshAsync: currentPageRefreshAsync_1,
                loadNextPage: loadNextPage_1,
            };
        }
        var paging = (data === null || data === void 0 ? void 0 : data.paging) || {
            currentPage: 1,
            totalCount: (_a = data === null || data === void 0 ? void 0 : data.tableData) === null || _a === void 0 ? void 0 : _a.length,
        };
        var currentPage = paging.currentPage, totalCount = paging.totalCount;
        currentPageRef.current = currentPage;
        dataSourceMapRef.current[currentPage] = (data === null || data === void 0 ? void 0 : data.tableData) || [];
        var keys = Object.keys(dataSourceMapRef.current).sort(function (a, b) { return Number(a) - Number(b); });
        var dataSource = keys.reduce(function (result, key) {
            return __spreadArray(__spreadArray([], result, true), dataSourceMapRef.current[key], true);
        }, []);
        var refreshAsync = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataSourceMapRef.current = {};
                        props.beforeRefreshCallback();
                        currentPageRef.current = 1;
                        return [4 /*yield*/, runAsync()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageRefreshAsync = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, runAsync()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var loadNextPage = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentPageRef.current += 1;
                        return [4 /*yield*/, runAsync()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        if (instance === null || instance === void 0 ? void 0 : instance.ref) {
            instance.ref.current.refresh = refreshAsync;
            instance.ref.current.load = currentPageRefreshAsync;
        }
        // end
        return {
            loading: loading,
            totalCount: totalCount,
            hasNextPage: Boolean(totalCount && dataSource.length < totalCount),
            columns: (data === null || data === void 0 ? void 0 : data.tableColumns) || undefined,
            dataSource: dataSource,
            refreshAsync: refreshAsync,
            currentPageRefreshAsync: currentPageRefreshAsync,
            loadNextPage: loadNextPage,
        };
    }, [hasRequestConfig, instance, data, loading, runAsync]);
}
