import { __rest } from "tslib";
import classNames from 'classnames';
import * as React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
export var FormatText = function (props) {
    var mode = props.mode, value = props.value;
    if (value && typeof value === 'object') {
        var _value = value.value, text = value.text, link_1 = value.link, toolTips = value.toolTips, rest = __rest(value, ["value", "text", "link", "toolTips"]);
        var style = rest;
        var content = (_value !== null && _value !== void 0 ? _value : text) || '- -';
        if (mode === 'simple') {
            return React.createElement(CnEllipsis, { style: style }, content);
        }
        return (React.createElement("span", { className: classNames({ 'cn-table-format-text-link': !!link_1 }), style: style, onClick: function () { return link_1 && window.open(link_1); } }, content));
    }
    if (mode === 'simple') {
        return React.createElement(CnEllipsis, null, value || '- -');
    }
    return React.createElement(React.Fragment, null, value || '- -');
};
