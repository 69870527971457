import { __assign, __rest } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { CnButton } from '@/components/cn-button';
import locale from '@/locale';
$i18n.init({ locale: locale, componentName: 'CnReset' });
export var CnReset = function (_a) {
    var forceClear = _a.forceClear, validate = _a.validate, onResetValidateFailed = _a.onResetValidateFailed, onResetValidateSuccess = _a.onResetValidateSuccess, form = _a.form, props = __rest(_a, ["forceClear", "validate", "onResetValidateFailed", "onResetValidateSuccess", "form"]);
    return (React.createElement(CnButton, __assign({}, props, { onClick: function (e) {
            if (props.onClick) {
                if (props.onClick(e) === false)
                    return;
            }
            form
                .reset('*', {
                forceClear: forceClear,
                validate: validate,
            })
                .then(onResetValidateSuccess)
                .catch(onResetValidateFailed);
        } }), props.children || $i18n.get({ id: 'Reset', dm: '重置', ns: 'CnReset' })));
};
