import { __assign, __spreadArray } from "tslib";
import { useMemo } from 'react';
export function useToolbarButtons(props) {
    var toolbar = props.toolbar, selectedKeys = props.selectedKeys, selectedDataSource = props.selectedDataSource;
    return useMemo(function () {
        var temp = (toolbar === null || toolbar === void 0 ? void 0 : toolbar.buttons) || __spreadArray(__spreadArray([], ((toolbar === null || toolbar === void 0 ? void 0 : toolbar.toolArea) || []), true), ((toolbar === null || toolbar === void 0 ? void 0 : toolbar.batchArea) || []), true);
        return temp
            .map(function (item) {
            try {
                if (typeof item === 'function')
                    return item([], []);
                return item;
            }
            catch (_a) { }
            return null;
        })
            .map(function (item) {
            try {
                if (typeof item.disabled === 'function') {
                    var disabled = item.disabled(__spreadArray([], selectedKeys, true), selectedDataSource);
                    return __assign(__assign({}, item), { disabled: disabled });
                }
                return item;
            }
            catch (_a) { }
            return null;
        })
            .filter(function (item) {
            var _a;
            // 无 children 时隐藏不展示
            if (!(item === null || item === void 0 ? void 0 : item.children))
                return false;
            // @ts-ignore hack 去掉导入导出组件
            if ((_a = item.props) === null || _a === void 0 ? void 0 : _a.createService)
                return false;
            return true;
        });
    }, [selectedDataSource, selectedKeys, toolbar]);
}
