import { __assign } from "tslib";
import React, { useContext } from 'react';
import { CnCard } from '@/components/cn-card';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CardItemsStateCtx } from '../../context/card-items';
import { ItemFormat } from '../item-format';
import classNames from 'classnames';
/** 卡片渲染 */
export var ItemCard = function (props) {
    var _a = useContext(CardItemsStateCtx), icon = _a.icon, title = _a.title, desc = _a.desc, action = _a.action, subIcon = _a.subIcon, subTitle = _a.subTitle, subDesc = _a.subDesc, subAction = _a.subAction, tags = _a.tags, primaryKey = _a.primaryKey, useDataIndex = _a.useDataIndex, showSelect = _a.showSelect, selectedKeys = _a.selectedKeys, setSelectedKeys = _a.setSelectedKeys, onSelectChange = _a.onSelectChange, disableDetail = _a.disableDetail, labelAlign = _a.labelAlign;
    var columns = useContext(CardItemsStateCtx).columns;
    var rowIndex = props.rowIndex, data = props.data, onClick = props.onClick;
    var primaryValue = useDataIndex ? rowIndex : data[primaryKey];
    var footAction = __assign({}, props.footAction);
    if (showSelect) {
        footAction.checkBox = {
            checked: selectedKeys.has(primaryValue),
            onChange: function (checked) {
                if (checked) {
                    setSelectedKeys(function (prev) {
                        var val = new Set(prev);
                        val.add(primaryValue);
                        onSelectChange(val);
                        return val;
                    });
                }
                else {
                    setSelectedKeys(function (prev) {
                        var val = new Set(prev);
                        val.delete(primaryValue);
                        onSelectChange(val);
                        return val;
                    });
                }
            },
        };
    }
    var isTop = labelAlign === 'top';
    return (React.createElement(CnCard, { className: "cn-ui-m-card-items-card", icon: icon ? icon(data) : undefined, title: title ? title(data) : undefined, desc: desc ? desc(data) : undefined, action: action ? action(data) : undefined, subIcon: subIcon ? subIcon(data) : undefined, subTitle: subTitle ? subTitle(data) : undefined, subDesc: subDesc ? subDesc(data) : undefined, subAction: subAction ? subAction(data) : undefined, tags: tags ? tags(data) : [], footAction: footAction },
        React.createElement("div", { onClick: onClick, className: classNames('cn-ui-m-card-items-card-content', {
                'cn-ui-m-card-items-card-with-detail': !disableDetail,
            }) }, columns.slice(0, 6).map(function (column, index) {
            var dataIndex = column.dataIndex;
            return (React.createElement("div", { key: "".concat(dataIndex, "-").concat(index), className: classNames('cn-ui-m-card-items-card-row', {
                    'cn-ui-m-card-items-card-row-top': isTop,
                    'cn-ui-m-card-items-card-row-left': !isTop,
                }) },
                isTop ? (React.createElement("div", { className: "cn-ui-m-card-items-card-row-label" }, column.title || dataIndex)) : (React.createElement(CnEllipsis, { className: "cn-ui-m-card-items-card-row-label" }, column.title || dataIndex)),
                React.createElement("div", { className: "cn-ui-m-card-items-card-row-value" },
                    React.createElement(ItemFormat, { mode: "simple", rowIndex: rowIndex, data: data, column: column }))));
        }))));
};
