import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { renderToContainer } from '@/utils/render-to-container';
import { CnBox } from '../cn-box';
import { ImageViewerSlider } from './components/image-viewer-slider';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnImageViewer(props) {
    var _src = props.src, width = props.width, height = props.height, single = props.single;
    var src = useMemo(function () {
        if (!_src)
            return [];
        if (typeof _src === 'string')
            return [_src];
        return _src;
    }, [_src]);
    var _a = useState(props.index || 0), index = _a[0], setIndex = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var onClose = useCallback(function () {
        setVisible(false);
    }, []);
    var node = (React.createElement(ImageViewerSlider, { setIndex: setIndex, index: index, value: src, onTap: onClose }));
    // 单图，宽100%，高自适应
    if (single) {
        return (React.createElement(React.Fragment, null,
            withNativeProps(props, React.createElement("div", { className: "cn-ui-m-image-single-viewer" },
                React.createElement("img", { src: src && src[0], onClick: function () {
                        setIndex(0);
                        setVisible(true);
                    } }))),
            visible ? renderToContainer(document.body, node) : null));
    }
    return (React.createElement(React.Fragment, null,
        withNativeProps(props, React.createElement(CnBox, { className: "cn-ui-m-image-viewer", wrap: true, direction: "row", spacing: 12 }, src.map(function (url, i) { return (React.createElement("div", { key: url, className: "cn-ui-m-image-viewer-item", style: { backgroundImage: "url(".concat(url, ")"), width: width, height: height }, onClick: function () {
                setIndex(i);
                setVisible(true);
            } })); }))),
        visible ? renderToContainer(document.body, node) : null));
}
CnImageViewer.displayName = 'CnImageViewer';
CnImageViewer.open = function (props) {
    var index = props.index, src = props.src;
    var getSrc = function () {
        if (!src)
            return [];
        if (typeof src === 'string')
            return [src];
        return src;
    };
    var container = document.createElement('div');
    document.body.appendChild(container);
    var hide = function () { return container.remove(); };
    ReactDOM.render(React.createElement(ImageViewerSlider, { index: index, value: getSrc(), onTap: hide }), container);
    return { hide: hide };
};
