import { __assign, __awaiter, __generator, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { getWidth } from '@/utils/element';
import { useGuid } from '@/utils/use-guid';
import { withNativeProps } from '@/utils/with-native-props';
import { CnStepItem } from './cn-step-item';
import { getStepCurrent } from './utils';
import './cn-step.scss';
/**
 * @category 组件
 * @description 步骤组件
 */
export var CnStep = function (props) {
    var direction = props.direction, shape = props.shape, children = props.children, disabled = props.disabled, onChange = props.onChange, contentRender = props.contentRender;
    var _a = React.useState(false), isOverflow = _a[0], setOverflow = _a[1];
    var classes = {
        step: classNames('cn-ui-m-step', {
            'cn-ui-m-step--scroll': isOverflow,
        }),
        container: classNames('cn-ui-m-step-container', "cn-ui-m-step-container--".concat(direction), {
            'cn-ui-m-step-container--scroll': isOverflow,
        }),
    };
    var validChildren = React.Children.toArray(children)
        .filter(React.isValidElement)
        .filter(function (child) {
        return child.type === CnStepItem;
    }) || [];
    var childrenCount = validChildren.length;
    var current = getStepCurrent(props);
    var disabledIndexes = validChildren
        .map(function (child, index) {
        return disabled || get(child, 'props.disabled') ? index : null;
    })
        .filter(function (item) { return item !== null; });
    var stepId = useGuid('step-');
    var stepContainerId = useGuid('step-container-');
    React.useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var containerWidth, width;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getWidth(stepContainerId)];
                    case 1:
                        containerWidth = _b.sent();
                        width = ((_a = document.getElementById(stepId)) === null || _a === void 0 ? void 0 : _a.scrollWidth) || 0;
                        setOverflow(width > containerWidth);
                        return [2 /*return*/];
                }
            });
        }); })();
    });
    var renderChildren = function () {
        return validChildren.map(function (child, index) {
            var childProps = child.props;
            var childDisabled = childProps.disabled, childStatusProp = childProps.status, childClassName = childProps.className, childOnClick = childProps.onClick, others = __rest(childProps, ["disabled", "status", "className", "onClick"]);
            var status = childStatusProp;
            if (!childStatusProp) {
                status = 'wait';
                if (index === current) {
                    status = 'process';
                }
                else if (index < current) {
                    status = 'finish';
                }
            }
            return React.cloneElement(child, __assign(__assign({ key: "cn_ui_m_step_".concat(index) }, others), { order: index, isFirst: index === 0, isLast: index === childrenCount - 1, isPreDisabled: disabledIndexes.indexOf(index - 1) > -1, shape: shape, direction: direction, status: status, contentRender: contentRender, onClick: function () {
                    if (childOnClick) {
                        childOnClick(index);
                    }
                    if (onChange && !disabled && !childDisabled) {
                        onChange(index);
                    }
                }, className: classNames(childClassName, "cn-ui-m-step-item--".concat(direction)) }));
        });
    };
    return withNativeProps(props, React.createElement("div", { id: stepContainerId, className: classes.step },
        React.createElement("div", { id: stepId, className: classes.container }, renderChildren())));
};
CnStep.displayName = 'CnStep';
CnStep.defaultProps = {
    direction: 'hoz',
    shape: 'circle',
    disabled: false,
};
