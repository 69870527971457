import { __assign, __rest } from "tslib";
import React from 'react';
import classnames from 'classnames';
import { useI18n } from 'panda-i18n';
import { RESULT_TYPE_MAP } from './constant';
import { getIcon } from './util';
import './index.scss';
export default function CnResultView(props) {
    // 拆除不需要向下传递的props参数
    var $i18n = useI18n();
    var className = props.className, _a = props.type, type = _a === void 0 ? 'info' : _a, mode = props.mode, _icon = props.icon, _b = props.title, title = _b === void 0 ? RESULT_TYPE_MAP($i18n)[type].title : _b, _c = props.subTitle, subTitle = _c === void 0 ? RESULT_TYPE_MAP($i18n)[type].subTitle : _c, extra = props.extra, _d = props.children, children = _d === void 0 ? null : _d, rest = __rest(props, ["className", "type", "mode", "icon", "title", "subTitle", "extra", "children"]);
    var icon = getIcon(_icon, type);
    return (React.createElement("div", __assign({ "data-name": "CnResult", className: classnames(className, 'cn-result', "cn-result-type-".concat(type), "cn-result-mode-".concat(mode)) }, rest),
        icon,
        title ? React.createElement("div", { className: "cn-result-title" }, title) : null,
        subTitle ? React.createElement("div", { className: "cn-result-subTitle" }, subTitle) : null,
        extra ? React.createElement("div", { className: "cn-result-extra" }, extra) : null,
        children));
}
CnResultView.defaultProps = {
    type: 'info',
    mode: 'page',
};
