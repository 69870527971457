import { withI18n } from 'panda-i18n';
import locale from '@/locale';
import { error, help, hide, loading, notice, show, success, warning, } from './func';
import { CnMessage as CnMessageView } from './message';
import './index.scss';
export var CnMessage = withI18n(CnMessageView, {
    locale: locale,
});
CnMessage.show = show;
CnMessage.hide = hide;
CnMessage.success = success;
CnMessage.error = error;
CnMessage.notice = notice;
CnMessage.help = help;
CnMessage.loading = loading;
CnMessage.warning = warning;
export default CnMessage;
