import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useMemo, useRef, useState } from 'react';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
export function useCnListDataSource(props) {
    var _this = this;
    var _a;
    var dataSourceMapRef = useRef({});
    var _b = useState(false), fullPageLoading = _b[0], setFullPageLoading = _b[1];
    var requestConfig = props.requestConfig || {};
    var hasRequestConfig = Boolean(props.requestConfig && ((_a = props.requestConfig) === null || _a === void 0 ? void 0 : _a.url));
    var config = useMemo(function () {
        if (!hasRequestConfig)
            return {};
        return __assign(__assign({ defaultParams: {
                currentPage: 1,
                pageSize: 10,
            } }, requestConfig), { method: (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method) || 'POST' });
    }, [requestConfig]);
    var _c = useCnRequest(config), data = _c.data, runAsync = _c.runAsync, loading = _c.loading;
    return useMemo(function () {
        var _a;
        if (!hasRequestConfig) {
            var refreshAsync_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            var currentPageRefreshAsync_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            var loadNextPage_1 = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); }); };
            // end
            return {
                loading: !!hasRequestConfig,
                hasNextPage: false,
                dataSource: (props === null || props === void 0 ? void 0 : props.dataSource) || [],
                refreshAsync: refreshAsync_1,
                currentPageRefreshAsync: currentPageRefreshAsync_1,
                loadNextPage: loadNextPage_1,
                fullPageLoading: false,
            };
        }
        var paging = (data === null || data === void 0 ? void 0 : data.paging) || {
            currentPage: 1,
            totalCount: (_a = data === null || data === void 0 ? void 0 : data.tableData) === null || _a === void 0 ? void 0 : _a.length,
        };
        var currentPage = paging.currentPage, totalCount = paging.totalCount;
        dataSourceMapRef.current[currentPage] = (data === null || data === void 0 ? void 0 : data.tableData) || [];
        var keys = Object.keys(dataSourceMapRef.current).sort(function (a, b) { return Number(a) - Number(b); });
        var dataSource = keys.reduce(function (result, key) {
            return __spreadArray(__spreadArray([], result, true), dataSourceMapRef.current[key], true);
        }, []);
        var refreshAsync = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setFullPageLoading(true);
                        dataSourceMapRef.current = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, runAsync({ currentPage: 1, pageSize: 10 })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        setFullPageLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageRefreshAsync = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, runAsync({ currentPage: currentPage, pageSize: 10 })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var loadNextPage = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, runAsync({ currentPage: currentPage + 1, pageSize: 10 })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        // end
        return {
            loading: loading,
            totalCount: totalCount,
            hasNextPage: Boolean(totalCount && dataSource.length < totalCount),
            dataSource: dataSource,
            refreshAsync: refreshAsync,
            currentPageRefreshAsync: currentPageRefreshAsync,
            loadNextPage: loadNextPage,
            fullPageLoading: fullPageLoading,
            paging: paging,
        };
    }, [hasRequestConfig, data, runAsync, fullPageLoading, dataSourceMapRef]);
}
