import $i18n from 'panda-i18n';
import React from 'react';
import { CnListItem } from '@/components/cn-list';
import classNames from 'classnames';
import { CnPopover } from '@/components/cn-popover';
import { CnButton } from '@/components/cn-button';
var CnEmployeeItem = function (props) {
    var _a = props.data, _b = _a.name, name = _b === void 0 ? '' : _b, _c = _a.nickName, nickName = _c === void 0 ? '' : _c, workNo = _a.workNo, personalPhotoUrl = _a.personalPhotoUrl, _d = _a.prefix, prefix = _d === void 0 ? 'cn-ui-m-' : _d, department = _a.department;
    var clsPrefix = "".concat(prefix, "employee-item");
    if (props.mini) {
        var showName = "".concat(name).concat(nickName ? "(".concat(nickName, ")") : '');
        var buttonText = $i18n.get({
            id: '37329441080348672.CNTM',
            dm: '阿里内外',
            ns: 'CnEmployeeSelect',
        });
        var buttonHref = "https://work.alibaba-inc.com/nwpipe/u/".concat(workNo);
        var cardContent = (React.createElement("div", { className: "".concat(clsPrefix, "-popover") },
            React.createElement("div", { className: "".concat(clsPrefix, "-popover-body") },
                React.createElement(CnListItem, { title: "".concat(name).concat(nickName ? "(".concat(nickName, ")") : ''), media: React.createElement("img", { src: personalPhotoUrl, className: "".concat(clsPrefix, "-avatar") }), subTitle: "".concat(workNo) }),
                React.createElement("div", { className: "".concat(clsPrefix, "-popover-department") }, department)),
            React.createElement("div", { className: "".concat(clsPrefix, "-popover-footer") },
                React.createElement(CnButton, { type: "secondary", component: "a", href: buttonHref, target: "_blank" }, buttonText))));
        return (React.createElement(CnPopover, { content: cardContent, trigger: "click", placement: "bottom", style: { padding: 0 }, className: "".concat(clsPrefix, "-popover-wrapper") },
            React.createElement("div", { className: classNames(clsPrefix, "".concat(clsPrefix, "-mini")) },
                React.createElement("img", { src: personalPhotoUrl, className: "".concat(clsPrefix, "-avatar") }),
                React.createElement("div", null, showName || workNo))));
    }
    return (React.createElement(CnListItem, { className: clsPrefix, title: "".concat(name).concat(nickName ? "(".concat(nickName, ")") : '', "  ").concat(workNo), media: React.createElement("img", { src: personalPhotoUrl, className: "".concat(clsPrefix, "-avatar") }), subTitle: department }));
};
export default CnEmployeeItem;
