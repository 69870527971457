import { __assign } from "tslib";
import './cn-input.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import { CnReadOnly } from '@/components/cn-read-only';
import { useFormLayout } from '@/form/cn-form-layout';
import { useControlled, withNativeProps } from '@cainiaofe/cn-ui-common';
import RightStateIcon from './components/right-state-icon';
import LengthTip from './components/length-tip';
import { clsPrefix } from './constant';
export var CnInput = React.forwardRef(function (props, ref) {
    var _a;
    var className = props.className, _b = props.size, size = _b === void 0 ? 'medium' : _b, insideForm = props.insideForm, _c = props.type, type = _c === void 0 ? 'normal' : _c, _d = props.placeholder, placeholder = _d === void 0 ? $i18n.get({
        id: 'gxms-pc_Enter.CNTM',
        dm: '请输入',
        ns: 'CnInput',
    }) : _d, disabled = props.disabled, _e = props.isPassword, isPasswordProp = _e === void 0 ? false : _e, htmlType = props.htmlType, enterKeyHint = props.enterKeyHint, stateProp = props.state, maxLength = props.maxLength, showLimitHint = props.showLimitHint, readOnly = props.readOnly, hasClear = props.hasClear, style = props.style, autoFocus = props.autoFocus, alignProp = props.align, highlightable = props.highlightable, _f = props.addonAfter, addonAfter = _f === void 0 ? null : _f, _g = props.addonBefore, addonBefore = _g === void 0 ? null : _g, canTogglePasswordShow = props.canTogglePasswordShow, onClear = props.onClear, onFocus = props.onFocus, onBlur = props.onBlur, onKeyDown = props.onKeyDown, onCompositionStart = props.onCompositionStart, onCompositionEnd = props.onCompositionEnd;
    var compositionStartRef = React.useRef(false);
    var inputRef = React.useRef(null);
    var isPassword = isPasswordProp || htmlType === 'password';
    var curSize = insideForm ? 'small' : size;
    var wrapperAlign = useFormLayout().wrapperAlign;
    var align = alignProp || wrapperAlign;
    var defaultTextType = isPassword ? 'password' : htmlType || 'text';
    var _h = React.useState(defaultTextType), textType = _h[0], setTextType = _h[1];
    var _j = React.useState(false), isFocused = _j[0], setIsFocused = _j[1];
    var _k = useControlled(props), value = _k[0], setValue = _k[1];
    // 右侧是否有 icon
    var hasIcon = hasClear || stateProp !== 'normal';
    React.useImperativeHandle(ref, function () { return ({
        clear: function () { return setValue(undefined); },
        focus: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
        blur: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
        get nativeElement() {
            return inputRef.current;
        },
    }); });
    return withNativeProps(props, React.createElement("div", { className: classNames(clsPrefix, className, "".concat(clsPrefix, "-size--").concat(curSize), "".concat(clsPrefix, "--").concat(type), (_a = {},
            _a["".concat(clsPrefix, "--").concat(curSize)] = !readOnly,
            _a["".concat(clsPrefix, "--hasIcon")] = hasIcon,
            _a["".concat(clsPrefix, "--highlighted")] = highlightable && isFocused,
            _a["".concat(clsPrefix, "--").concat(align)] = readOnly,
            _a)) },
        addonBefore,
        readOnly ? (React.createElement(CnReadOnly, { value: value })) : (React.createElement("input", { ref: inputRef, className: classNames("".concat(clsPrefix, "-wrapper"), "".concat(clsPrefix, "--").concat(curSize), "".concat(clsPrefix, "--").concat(align)), style: __assign({}, style), type: textType, value: value || '', placeholder: placeholder, autoFocus: autoFocus, maxLength: maxLength, disabled: !!disabled, onChange: function (e) {
                e.stopPropagation();
                var val = e.target.value;
                if (typeof maxLength === 'number' && maxLength > 0) {
                    setValue(val.substring(0, maxLength), e);
                    return;
                }
                setValue(val, e);
            }, onBlur: function (e) {
                onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
                setIsFocused(false);
            }, onFocus: function (e) {
                onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
                setIsFocused(true);
            }, onKeyDown: onKeyDown, 
            // @ts-ignore 忽略检测
            enterKeyHint: enterKeyHint, onCompositionStart: function (e) {
                compositionStartRef.current = true;
                onCompositionStart === null || onCompositionStart === void 0 ? void 0 : onCompositionStart(e);
            }, onCompositionEnd: function (e) {
                compositionStartRef.current = false;
                onCompositionEnd === null || onCompositionEnd === void 0 ? void 0 : onCompositionEnd(e);
            } })),
        readOnly || disabled ? null : (React.createElement(LengthTip, { maxLength: maxLength, showLimitHint: showLimitHint, value: value })),
        React.createElement(RightStateIcon, { setValue: setValue, defaultTextType: defaultTextType, textType: textType, setTextType: setTextType, isPassword: isPassword, canTogglePasswordShow: canTogglePasswordShow, onClear: onClear, htmlType: htmlType, hasClear: hasClear && !readOnly && !disabled && Boolean(value), state: stateProp }),
        addonAfter));
});
CnInput.displayName = 'CnInput';
