import './index.scss';
import $i18n from 'panda-i18n';
import React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { calcFileSize, canUseImageViewer, useShowUploadDownloadIcon, useShowUploadRemoveIcon, useShowUploadPreviewIcon, useUploadState, } from '@cainiaofe/cn-ui-common';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { StaticFileIcon } from './icons';
import { CnIcon } from '@/components/cn-icon';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { CnProgress } from '@/components/cn-progress';
export var UploadListItem = function (_a) {
    var file = _a.file;
    var _b = useUploadState(), props = _b.props, readOnly = _b.readOnly, onRemove = _b.onRemove;
    var disabled = props.disabled;
    var showRemove = useShowUploadRemoveIcon({
        showRemove: !disabled && !readOnly && props.showRemove,
        file: file,
    });
    var showDownload = useShowUploadDownloadIcon({
        showDownload: props.showDownload,
        onDownload: props.onDownload,
        file: file,
    });
    var onDownload = function (_file) {
        if (props.onDownload)
            props.onDownload(_file);
        else
            downloadFile(_file.url, _file.name);
    };
    var showPreview = useShowUploadPreviewIcon({
        showPreview: props.showPreview,
        onPreview: props.onPreview,
        file: file,
    });
    var onPreview = function (_file) {
        if (props.onPreview) {
            props.onPreview(_file);
            return;
        }
        var src = _file.objectUrl || _file.url;
        if (canUseImageViewer(_file)) {
            CnImageViewer.open({ src: src });
            return;
        }
        if (src)
            window.open(src);
    };
    return (React.createElement("div", { className: "cn-oss-upload-list-item" },
        React.createElement("div", { className: "cn-oss-upload-list-item-wrapper" },
            React.createElement("div", { className: "cn-oss-upload-list-item-content", onClick: function () {
                    if (!showPreview)
                        return;
                    onPreview(file);
                } },
                React.createElement(StaticFileIcon, { className: "cn-oss-upload-list-item-file-ext", file: file }),
                React.createElement("div", { className: "cn-oss-upload-list-item-info" },
                    React.createElement(CnEllipsis, { className: "cn-oss-upload-list-item-info-name", ellipsisPosition: "middle", endCharCount: 4, hasTooltip: false }, file.name),
                    file.size ? (React.createElement("div", { className: "cn-oss-upload-list-item-info-size" },
                        "(",
                        calcFileSize(file.size),
                        ")")) : null)),
            showRemove ? (React.createElement("div", { className: "cn-oss-upload-list-item-actions" },
                React.createElement("div", { onClick: function () { return onRemove(file); }, className: "cn-oss-upload-list-item-actions-item" },
                    React.createElement(CnIcon, { type: "close", size: "small" })))) : null,
            showDownload ? (React.createElement("div", { className: "cn-oss-upload-list-item-actions" },
                React.createElement("div", { onClick: function (event) {
                        event.stopPropagation();
                        if (onDownload)
                            onDownload(file);
                        else
                            window.open(file.downloadUrl || file.url);
                    }, className: "cn-oss-upload-list-item-actions-item" },
                    React.createElement(CnIcon, { type: "download", size: "small" })))) : null),
        !readOnly && file.status === 'error' ? (React.createElement("div", { className: "cn-oss-upload-list-item-error" }, file.errorMsg ||
            $i18n.get({
                id: 'UploadFailed',
                dm: '上传失败',
                ns: 'CnOssUpload',
            }))) : null,
        !readOnly && file.percent >= 0 ? (React.createElement("div", { className: "cn-oss-upload-list-item-progress" },
            React.createElement(CnProgress, { percent: file.percent, textRender: function () { return null; } }))) : null));
};
