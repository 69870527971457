import { isNumber } from '@/utils/func';
export function toNumber(num) {
    if (!isNumber(num)) {
        return num;
    }
    return Number(num);
}
export function clamp(value, min, max) {
    if (!isNumber(value)) {
        return value;
    }
    if (value > max) {
        return max;
    }
    else if (value < min) {
        return min;
    }
    else {
        return value;
    }
}
/**
 * 值无效时，获取最大值
 * @param min
 * @param max
 * @param threshold
 */
export function getDefaultValue(min, max, threshold) {
    if (min && min > -threshold) {
        return toNumber(min);
    }
    else if (max && max < threshold) {
        return toNumber(max);
    }
    return 0;
}
