import * as React from 'react';
import './index.scss';
import classNames from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnSafeArea(props) {
    var _a = props.position, position = _a === void 0 ? 'bottom' : _a, className = props.className;
    var halfRef = React.useRef(null);
    var _b = React.useState(false), needOffset = _b[0], setNeedOffset = _b[1];
    React.useEffect(function () {
        if (position !== 'bottom')
            return;
        var temp = (function () {
            var dom = halfRef.current;
            if (!dom)
                return false;
            try {
                var domStyle = dom.getBoundingClientRect();
                return domStyle.height > 0;
            }
            catch (_a) { }
            return false;
        })();
        setNeedOffset(temp);
    }, [position]);
    if (position === 'bottom') {
        return (React.createElement(React.Fragment, null,
            withNativeProps(props, React.createElement("div", { ref: halfRef, className: classNames('cn-ui-m-half-safe-area-inset-bottom') })),
            needOffset ? (React.createElement("div", { className: "cn-ui-m-half-safe-area-inset-bottom-offset" })) : null));
    }
    return null;
}
CnSafeArea.displayName = 'CnSafeArea';
