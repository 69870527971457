import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import classNames from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';
import { CnScrollView } from '../cn-scroll-view';
import { CnListItem } from './item';
import { CnLoading } from '../cn-loading';
import { useCnListDataSource } from './service';
import { CnResult } from '@/form/cn-result';
import { CnPageFooter } from '../cn-page-footer';
var List = function (props, ref) {
    var _a;
    var children = props.children, _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, className = props.className, dataSourceProps = props.dataSource, requestConfig = props.requestConfig, showBorderRadius = props.showBorderRadius, renderItem = props.renderItem, paggingProps = props.pagging, others = __rest(props, ["children", "prefix", "className", "dataSource", "requestConfig", "showBorderRadius", "renderItem", "pagging"]);
    var baseCnListRef = React.useRef(null);
    var _c = useCnListDataSource(props), dataSource = _c.dataSource, loadNextPage = _c.loadNextPage, hasNextPage = _c.hasNextPage, refreshAsync = _c.refreshAsync, currentPageRefreshAsync = _c.currentPageRefreshAsync, fullPageLoading = _c.fullPageLoading, paging = _c.paging;
    React.useImperativeHandle(ref, function () {
        var _a;
        return (__assign({ refresh: refreshAsync, load: currentPageRefreshAsync }, ((_a = baseCnListRef === null || baseCnListRef === void 0 ? void 0 : baseCnListRef.current) !== null && _a !== void 0 ? _a : {})));
    });
    var customContent = function () {
        var emptyDataSource = !Array.isArray(dataSource) || dataSource.length < 1;
        if (children && emptyDataSource) {
            return null;
        }
        if (emptyDataSource) {
            return React.createElement(CnResult, { type: "empty" });
        }
        return dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (item, index) {
            var key = item.key || index;
            return renderItem ? (renderItem(item, index)) : (React.createElement(CnListItem, __assign({ key: key }, item, { onClick: function (event) {
                    if (item.onClick) {
                        item.onClick(event, item);
                    }
                } })));
        });
    };
    return (React.createElement("div", __assign({}, others, { ref: baseCnListRef, className: classNames("".concat(prefix, "list"), className, (_a = {},
            _a["".concat(prefix, "list-show-border-radius")] = showBorderRadius,
            _a)) }),
        React.createElement(CnLoading, { visible: fullPageLoading },
            React.createElement(CnScrollView, { scrollEventThrottle: 1000, hasMore: hasNextPage, loadMore: loadNextPage, style: { overflow: 'unset' } },
                customContent(),
                children)),
        paggingProps && (paging === null || paging === void 0 ? void 0 : paging.totalCount) ? (React.createElement(CnPageFooter, null, "".concat($i18n.get({ id: 'Total', dm: '共', ns: 'CnList' }), " ").concat(paging === null || paging === void 0 ? void 0 : paging.totalCount, " ").concat($i18n.get({ id: 'Article', dm: '条', ns: 'CnList' })))) : null));
};
var CnList = forwardRef(List);
CnList.displayName = 'CnList';
export { CnList };
