import { __assign } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import locale from '@/locale';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useState, } from 'react';
import classNames from 'classnames';
import { CnPopup } from '@/components/cn-dialog';
import { mergeProps } from './with-default-props';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import { usePropsValue } from '../../utils/use-props-value';
import { CnPickerView } from '@/components/cn-picker-view';
import { generateColumnsExtend, useColumnsExtend, } from '@/components/cn-picker-view/columns-extend';
import { useMemoizedFn } from 'ahooks';
import { CnSafeArea } from '@/components/cn-safe-area';
import { defaultRenderLabel } from './picker-utils';
var classPrefix = 'cn-ui-m-picker';
var defaultProps = {
    defaultValue: [],
    closeOnMaskClick: true,
    renderLabel: defaultRenderLabel,
    destroyOnClose: false,
    forceRender: false,
};
var Picker = memo(forwardRef(function (p, ref) {
    var _a;
    var props = mergeProps(defaultProps, {
        confirmText: $i18n.get({ id: 'Determine', dm: '确定', ns: 'CnPicker' }),
        cancelText: $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnPicker' }),
    }, p);
    var _b = usePropsValue({
        value: props.visible,
        defaultValue: false,
        onChange: function (v) {
            var _a;
            if (v === false) {
                (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        },
    }), visible = _b[0], setVisible = _b[1];
    var actions = {
        toggle: function () {
            setVisible(function (v) { return !v; });
        },
        open: function () {
            setVisible(true);
        },
        close: function () {
            setVisible(false);
        },
    };
    useImperativeHandle(ref, function () { return actions; });
    var _c = usePropsValue(__assign(__assign({}, props), { onChange: function (val) {
            var _a;
            var extend = generateColumnsExtend(props.columns, val);
            (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props, val, extend);
        } })), value = _c[0], setValue = _c[1];
    var extend = useColumnsExtend(props.columns, value);
    var _d = useState(value), innerValue = _d[0], setInnerValue = _d[1];
    useEffect(function () {
        if (innerValue !== value) {
            setInnerValue(value);
        }
    }, [visible]);
    useEffect(function () {
        if (!visible) {
            setInnerValue(value);
        }
    }, [value]);
    var onChange = useMemoizedFn(function (val, ext) {
        var _a;
        setInnerValue(val);
        if (visible) {
            (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, val, ext);
        }
    });
    var pickerElement = withNativeProps(props, React.createElement("div", { className: classPrefix, "data-testid": "cn-picker" },
        React.createElement("div", { className: "".concat(classPrefix, "-header") },
            React.createElement("a", { role: "button", className: "".concat(classPrefix, "-header-button"), onClick: function () {
                    var _a;
                    (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
                    setVisible(false);
                } }, props.cancelText),
            React.createElement("div", { className: "".concat(classPrefix, "-header-title") }, props.title),
            React.createElement("a", { role: "button", className: classNames("".concat(classPrefix, "-header-button"), props.loading && "".concat(classPrefix, "-header-button-disabled")), onClick: function () {
                    if (props.loading)
                        return;
                    setValue(innerValue, true);
                    setVisible(false);
                }, "aria-disabled": props.loading }, props.confirmText)),
        React.createElement("div", { className: "".concat(classPrefix, "-body") },
            React.createElement(CnPickerView, { loading: props.loading, loadingContent: props.loadingContent, columns: props.columns, renderLabel: props.renderLabel, value: innerValue, mouseWheel: props.mouseWheel, onChange: onChange }))));
    var popupElement = (React.createElement(CnPopup, { style: props.popupStyle, className: classNames("".concat(classPrefix, "-popup"), props.popupClassName), visible: visible, closeMode: ['mask'], placement: "bottom", onMaskClick: function () {
            var _a;
            if (!props.closeOnMaskClick)
                return;
            (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
            setVisible(false);
        }, getContainer: props.getContainer, destroyOnClose: props.destroyOnClose, afterShow: props.afterShow, afterClose: props.afterClose, onClick: props.onClick, forceRender: props.forceRender, stopPropagation: props.stopPropagation },
        pickerElement,
        React.createElement(CnSafeArea, { position: "bottom" })));
    return (React.createElement(React.Fragment, null,
        popupElement, (_a = props.children) === null || _a === void 0 ? void 0 :
        _a.call(props, extend.items, actions)));
}));
Picker.displayName = 'CnPicker';
var CnPicker = withI18n(Picker, { locale: locale });
export { CnPicker };
