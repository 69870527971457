import { withI18n } from 'panda-i18n';
import locale from '@/locale';
import { attachPropertiesToComponent } from '@/utils/attach-properties-to-component';
import { CnMonthPickerPro } from './month-picker';
import { CnYearPickerPro } from './year-picker';
import { CnWeekPickerPro } from './week-picker';
import { CnRangeDatePickerPro } from './range-date-picker';
import { prompt } from './prompt';
import UICnDatePicker from './date-picker';
UICnDatePicker.displayName = 'CnDatePickerPro';
var CnDatePicker = withI18n(UICnDatePicker, { locale: locale });
var CnDatePickerPro = attachPropertiesToComponent(CnDatePicker, {
    prompt: prompt,
});
CnDatePickerPro.displayName = 'CnDatePickerPro';
export { CnDatePickerPro, CnDatePickerPro as CnDatePicker, CnMonthPickerPro, CnYearPickerPro, CnWeekPickerPro, CnRangeDatePickerPro, CnRangeDatePickerPro as CnDateRangePickerPro, };
