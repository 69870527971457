import { __assign } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import omit from 'lodash/omit';
import './cn-tag.scss';
/**
 * @category 组件
 * @description 标签
 */
export var CnTag = function (props) {
    var _a;
    var children = props.children, type = props.type, disabled = props.disabled;
    var classes = cx((_a = {
            'cn-ui-m-tag': true
        },
        _a["cn-ui-m-tag-".concat(type)] = !!type,
        _a['cn-ui-m-tag-disabled'] = !!disabled,
        _a));
    return withNativeProps(props, React.createElement("div", __assign({ className: classes }, (omit(props, 'className'))), children));
};
CnTag.displayName = 'CnTag';
CnTag.defaultProps = {
    type: 'normal',
};
