import { __assign, __rest } from "tslib";
import './styles/index.scss';
import React from 'react';
import { withI18n } from 'panda-i18n';
import { CnCascaderSelect as CnCascaderSelectView } from './cascader-select';
import locale from '@/locale';
import { CnCascaderSelectV2 } from '../cn-cascader-select-v2';
var CascaderSelect = function (props) {
    var version = props.version, restProps = __rest(props, ["version"]);
    if (version === 'v2') {
        return (React.createElement(CnCascaderSelectV2, { dataSource: restProps.dataSource, disabled: props.disabled, readOnly: props.readOnly, hasClear: props.hasClear, defaultValue: props.defaultValue, value: props.value, placeholder: props.placeholder, separator: props.separator, rootTipText: props.rootTipText, size: props.size, className: props.className, drawerClassName: props.drawerClassName, onChange: props.onChange, loadData: props.loadData, onSelect: props.onSelect, renderContent: props.renderContent, insideFilter: props.insideFilter }));
    }
    return React.createElement(CnCascaderSelectView, __assign({}, restProps));
};
export var CnCascaderSelect = withI18n(CascaderSelect, {
    locale: locale,
});
