import { __awaiter, __generator } from "tslib";
import React, { useState } from 'react';
import { CnListItem } from '@/components/cn-list';
import { CnIcon } from '../cn-icon';
function LoadingIcon(_a) {
    var visible = _a.visible;
    if (visible) {
        return (React.createElement(CnIcon, { className: "cn-ui-m-cascader-icon--loading", type: "progressing", size: "small" }));
    }
    return null;
}
export function CascaderListItem(props) {
    var _this = this;
    var _a, _b;
    var value = props.value, onClick = props.onClick, data = props.data, loadData = props.loadData;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var loadingVisible = !data.isLeaf && !((_a = data.children) === null || _a === void 0 ? void 0 : _a.length) && Boolean(loadData);
    return (React.createElement(CnListItem, { key: data.value, className: data.value === value ? 'cn-ui-m-cascader-list-item-checked' : '', title: data.label, extra: React.createElement(LoadingIcon, { visible: loading }), arrow: ((_b = data.children) === null || _b === void 0 ? void 0 : _b.length) ? 'right' : undefined, disabled: data.disabled, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(loadingVisible && loadData)) return [3 /*break*/, 2];
                        setLoading(true);
                        return [4 /*yield*/, loadData(data)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        onClick(data);
                        return [2 /*return*/];
                }
            });
        }); } }));
}
