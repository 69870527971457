import { __awaiter, __generator } from "tslib";
import * as React from 'react';
export var usePageFooterHeight = function (props) {
    var ref = props.ref, isOuterCnPage = props.isOuterCnPage;
    var calcHeight = function () {
        var dom = ref.current;
        if (!dom)
            return undefined;
        return dom.getBoundingClientRect().height;
    };
    var _a = React.useState(calcHeight()), height = _a[0], setHeight = _a[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(function () {
        setHeight(calcHeight());
    });
    React.useEffect(function () {
        if (!height)
            return;
        var dom = ref.current;
        if (!dom)
            return;
        if (isOuterCnPage) {
            var placeholder_1;
            var getPlaceHolder_1 = function (times) {
                if (times === void 0) { times = 0; }
                return __awaiter(void 0, void 0, Promise, function () {
                    var temp;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (times > 1000)
                                    return [2 /*return*/];
                                temp = document.querySelector('.cn-ui-m-page-footer-placeholder');
                                if (temp)
                                    return [2 /*return*/, temp];
                                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 10); })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, getPlaceHolder_1(times + 1)];
                            case 2: return [2 /*return*/, _a.sent()];
                        }
                    });
                });
            };
            getPlaceHolder_1().then(function (_placeholder) {
                if (!_placeholder)
                    return;
                placeholder_1 = _placeholder;
                placeholder_1.style.height = "".concat(height, "px");
            });
            return function () {
                if (placeholder_1)
                    placeholder_1.style.height = '0px';
            };
        }
        var pageDom = dom.closest('.cn-ui-m-page');
        if (!pageDom)
            return;
        var placeholder = pageDom.querySelector('.cn-ui-m-page-footer-placeholder');
        if (!placeholder)
            return;
        placeholder.style.height = "".concat(height, "px");
        return function () {
            placeholder.style.height = '0px';
        };
    }, [height, isOuterCnPage, ref]);
    return height;
};
