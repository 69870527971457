import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CnCard } from '@/components/cn-card';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnModal } from '@/components/cn-modal';
import { CnDrawerFooter } from './footer';
var classNamePrefix = 'cn-ui-m-drawer';
export var CnDrawer = function (props) {
    var _a;
    var className = props.className, _b = props.placement, placement = _b === void 0 ? 'bottom' : _b, _c = props.visible, visible = _c === void 0 ? false : _c, _d = props.titleAlign, titleAlign = _d === void 0 ? 'left' : _d, height = props.height, _e = props.closeMode, closeMode = _e === void 0 ? ['mask', 'close'] : _e, footer = props.footer, width = props.width, title = props.title, okProps = props.okProps, onOk = props.onOk, cancelProps = props.cancelProps, onCancel = props.onCancel, containerClassName = props.containerClassName, noCard = props.noCard, embeddable = props.embeddable, _f = props.onClose, onClose = _f === void 0 ? function () { } : _f, children = props.children, _g = props.style, style = _g === void 0 ? {} : _g, others = __rest(props, ["className", "placement", "visible", "titleAlign", "height", "closeMode", "footer", "width", "title", "okProps", "onOk", "cancelProps", "onCancel", "containerClassName", "noCard", "embeddable", "onClose", "children", "style"]);
    var drawerCls = classNames(classNamePrefix, className, "".concat(classNamePrefix, "--").concat(placement));
    var _h = useState(visible), drawerVisible = _h[0], setVisible = _h[1];
    var _j = okProps || {}, _k = _j.children, okChildren = _k === void 0 ? $i18n.get({
        id: 'Confirm',
        dm: '确认',
        ns: 'CnDrawer',
    }) : _k, _l = _j.visible, okVisible = _l === void 0 ? true : _l, restOkProps = __rest(_j, ["children", "visible"]);
    var _m = cancelProps || {}, _o = _m.children, cancelChildren = _o === void 0 ? $i18n.get({
        id: 'Cancel',
        dm: '取消',
        ns: 'CnDrawer',
    }) : _o, _p = _m.visible, cancelVisible = _p === void 0 ? true : _p, restCancelProps = __rest(_m, ["children", "visible"]);
    var showCloseIcon = Array.isArray(closeMode) && closeMode.indexOf('close') > -1;
    var hasCard = useCallback(function () {
        var _a;
        if (noCard) {
            return true;
        }
        if (embeddable) {
            return true;
        }
        var types = [];
        if (Array.isArray(children) && children.length > 0) {
            types = children.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName; });
        }
        else {
            types = [((_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName) || ''];
        }
        return types.includes('CnCard');
    }, [children, noCard, embeddable]);
    // 临时解决 drawer 的微信小程序 光标覆盖问题
    useEffect(function () {
        if (visible) {
            setTimeout(function () {
                setVisible(true);
            }, 50);
        }
        else {
            setVisible(visible);
        }
    }, [visible]);
    return (React.createElement(CnModal, __assign({}, others, { maskClosable: closeMode.indexOf('mask') > -1, onClose: onClose, visible: drawerVisible, placement: placement, className: "".concat(classNamePrefix, "-modal"), containerClassName: drawerCls, containerStyle: __assign({ width: width, height: height }, style) }),
        React.createElement("div", { className: "".concat(classNamePrefix, "-container") },
            title ? (React.createElement("span", { className: classNames("".concat(classNamePrefix, "-title"), (_a = {},
                    _a["".concat(classNamePrefix, "-title--center")] = titleAlign === 'center',
                    // todo: 居中对齐有样式上的问题：
                    // 1. 如果文字超出，则文字会与  close icon 重叠
                    // 2. 如果设置多余的右边距，则居中对齐且文字较少时，文案不是绝对局中的
                    // 目前技术无法低成本做到判断文字超出与否，并特殊处理
                    _a["".concat(classNamePrefix, "-title--with-icon")] = titleAlign === 'left' && showCloseIcon,
                    _a)) }, title)) : null,
            showCloseIcon ? (React.createElement("div", { className: "".concat(classNamePrefix, "-close"), onClick: function () {
                    onClose('close');
                } },
                React.createElement(CnIcon, { className: "".concat(classNamePrefix, "-close-icon"), type: "close" }))) : null,
            React.createElement("div", { className: classNames("".concat(classNamePrefix, "-body"), containerClassName) }, hasCard() ? children : React.createElement(CnCard, null, children)),
            footer && (React.createElement(CnDrawerFooter, null,
                footer,
                cancelVisible ? (React.createElement(CnButton, __assign({ fullWidth: true, size: "large", onClick: onCancel }, restCancelProps), cancelChildren)) : null,
                okVisible ? (React.createElement(CnButton, __assign({ type: "primary", fullWidth: true, size: "large", onClick: onOk }, restOkProps), okChildren)) : null)))));
};
CnDrawer.displayName = 'CnDrawer';
