export var canUseDom = !!(typeof window !== 'undefined' &&
    typeof document !== 'undefined' &&
    window.document &&
    window.document.createElement);
export var supportsPassive = false;
if (canUseDom) {
    try {
        var opts = {};
        Object.defineProperty(opts, 'passive', {
            get: function () {
                supportsPassive = true;
                return supportsPassive;
            },
        });
        window.addEventListener('test-passive', null, opts);
    }
    catch (e) { }
}
