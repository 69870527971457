import { __assign, __awaiter, __generator } from "tslib";
import React, { forwardRef, useCallback, useContext, useMemo, useRef, } from 'react';
import { CnPageFooter } from '@/components/cn-page-footer';
import { List } from './components/list';
import { ListSubCard } from './components/list-sub-card';
import { Toolbar } from './components/toolbar';
import { CardItemsContext, CardItemsStateCtx } from './context/card-items';
import { useIsInCard } from './hooks/use-is-in-card';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var Content = function (props) {
    var state = useContext(CardItemsStateCtx);
    var _a = useIsInCard(), isInCard = _a.isInCard, conRef = _a.conRef;
    if (isInCard)
        return React.createElement(ListSubCard, null);
    return withNativeProps(props, React.createElement("div", { ref: conRef, className: "cn-ui-m-card-items-container", "data-testid": "cn-card-items-container" },
        React.createElement(List, null),
        state.showToolbar ? (React.createElement(CnPageFooter, null,
            React.createElement(Toolbar, __assign({}, state)))) : null));
};
export var CardItems = forwardRef(function (props, ref) {
    return (React.createElement(CardItemsContext, { tableProps: props, ref: ref },
        React.createElement(Content, __assign({}, props))));
});
CardItems.defaultProps = {
    labelAlign: 'left',
    detailLabelAlign: 'top',
};
CardItems.displayName = 'CnCardItems';
// @ts-ignore 该逻辑后期会下线
CardItems.useRemote = function () {
    var ref = useRef({});
    var refresh = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.refresh))
                        return [2 /*return*/];
                    return [4 /*yield*/, ref.current.refresh()];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var load = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.load))
                        return [2 /*return*/];
                    return [4 /*yield*/, ref.current.load()];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    return useMemo(function () { return ({ ref: ref, refresh: refresh, load: load }); }, [refresh, load]);
};
// @ts-ignore 该逻辑后期会下线
CardItems.createRemoteInstance = function () {
    var ref = {
        current: {},
    };
    var refresh = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.refresh))
                        return [2 /*return*/];
                    return [4 /*yield*/, ref.current.refresh()];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var load = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.load))
                        return [2 /*return*/];
                    return [4 /*yield*/, ref.current.load()];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return { ref: ref, refresh: refresh, load: load };
};
