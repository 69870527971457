import { __assign } from "tslib";
import './index.scss';
import * as React from 'react';
import classNames from 'classnames';
import { useI18n } from 'panda-i18n';
import { toJS } from '@formily/reactive';
import { createForm, onFieldInputValueChange } from '@formily/core';
import { FormProvider } from '@formily/react';
import { SchemaField } from '@/form/schema-field';
import { CnButton } from '@/components/cn-button';
import { CnDrawer } from '@/components/cn-drawer';
import { CnIcon } from '@/components/cn-icon';
import { useDeepCompareEffect } from 'ahooks';
import { useSplitFilter } from './quick-filter-render';
import { CnButtonGroup } from '../cn-button-group';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnFilter(props) {
    var form = props.form, _a = props.formProps, formProps = _a === void 0 ? {} : _a, isSticky = props.isSticky, onChange = props.onChange, submit = props.onSubmit, reset = props.onReset, propsOnSearch = props.onSearch, children = props.children, hiddenFilterButton = props.hiddenFilterButton, components = props.components, schema = props.schema, positionFix = props.positionFix;
    var $i18n = useI18n();
    // @ts-ignore
    var _b = React.useState({}), forceUpdate = _b[1];
    var _c = React.useState(false), drawerVisible = _c[0], setDrawerVisible = _c[1];
    var normalizeChild = React.Children.toArray(children);
    var _d = useSplitFilter(normalizeChild), quickFilterChild = _d[0], quickWarpFilterChild = _d[1], normalFilterChild = _d[2], searchChild = _d[3];
    var valueChainEffects = React.useCallback(function (formIns) {
        onFieldInputValueChange('*', function (field) {
            var _a;
            var _b = field.getState(), _c = _b.path, path = _c === void 0 ? '' : _c, value = _b.value;
            // 兼容 PC => H5 一码多端情况，额外设定 mobileProps
            var fieldProps = (field.props || {});
            if (fieldProps.quick || ((_a = fieldProps.mobileProps) === null || _a === void 0 ? void 0 : _a.quick)) {
                formIns.setValuesIn(path, value);
                onSubmit(null);
            }
            var values = formIns.getValuesIn('*');
            // proxy数据类型转成普通类型
            onChange && onChange(toJS(values));
        });
    }, [onChange]);
    var formInstance = React.useMemo(function () {
        if (form) {
            form.addEffects('valueChainEffects', valueChainEffects);
            return form;
        }
        // formProps 处理逻辑
        return createForm(__assign(__assign({}, formProps), { effects: valueChainEffects }));
    }, [form]);
    useDeepCompareEffect(function () {
        if (formProps.values) {
            Object.keys(formProps.values).forEach(function (valueKey) {
                var _a;
                formInstance.setValuesIn(valueKey, (_a = formProps.values) === null || _a === void 0 ? void 0 : _a[valueKey]);
            });
        }
    }, [formProps.values, formInstance]);
    var hideDrawer = function () {
        setDrawerVisible(false);
    };
    var onSearch = function () {
        var values = formInstance.getValuesIn('*');
        propsOnSearch && propsOnSearch(toJS(values));
        forceUpdate({});
    };
    var onSubmit = function (e) {
        e && e.preventDefault();
        formInstance.submit(function (res) {
            submit && submit(toJS(res));
            onSearch();
            hideDrawer();
        });
    };
    var onReset = function () {
        formInstance.reset();
        reset && reset();
    };
    if (formInstance) {
        formInstance.filterSearch = onSubmit;
    }
    return (React.createElement(FormProvider, { form: formInstance },
        withNativeProps(props, React.createElement("div", { 
            // className='cn-ui-m-filter'
            className: classNames('cn-ui-m-filter', {
                'cn-ui-m-filter-position-fix': positionFix,
                'cn-ui-m-filter-sticky': isSticky,
            }), "data-testid": "cn-filter" },
            searchChild,
            (quickFilterChild === null || quickFilterChild === void 0 ? void 0 : quickFilterChild.length) || !hiddenFilterButton ? (React.createElement("div", { className: "cn-ui-m-filter-flex" },
                (quickFilterChild === null || quickFilterChild === void 0 ? void 0 : quickFilterChild.length) ? (React.createElement("div", { className: "cn-ui-m-filter-header" }, quickFilterChild)) : null,
                !hiddenFilterButton && (React.createElement("div", { className: "cn-ui-m-filter-footer", onClick: function () { return setDrawerVisible(true); } },
                    (quickFilterChild === null || quickFilterChild === void 0 ? void 0 : quickFilterChild.length) ? (React.createElement("div", { className: "cn-ui-m-filter-btn-divider" })) : null,
                    React.createElement(CnIcon, { className: "cn-ui-m-filter-btn-icon", type: "icon-filter", size: "small" }),
                    React.createElement("span", { className: "cn-ui-m-filter-btn-text" }, $i18n.get({
                        id: '31255239508234240.CNTM',
                        dm: '筛选',
                        ns: 'CnFilter',
                    })))))) : null,
            (quickWarpFilterChild === null || quickWarpFilterChild === void 0 ? void 0 : quickWarpFilterChild.length) ? (React.createElement("div", { className: "cn-ui-m-filter-no-warp" }, quickWarpFilterChild)) : null)),
        React.createElement(CnDrawer, { visible: drawerVisible, title: $i18n.get({
                id: '31255239508234240.CNTM',
                dm: '筛选',
                ns: 'CnFilter',
            }), placement: "bottom", onClose: hideDrawer, cancelProps: {
                children: null,
                visible: false,
            }, okProps: {
                children: null,
                visible: false,
            }, footer: React.createElement(CnButtonGroup, { fullWidth: true, size: "large", isReverse: true },
                React.createElement(CnButton, { type: "primary", onClick: function (e) { return onSubmit(e, 'normal'); } }, $i18n.get({
                    id: '31255238698733568.CNTM',
                    dm: '查询',
                    ns: 'CnFilter',
                })),
                React.createElement(CnButton, { onClick: hideDrawer }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnFilter' })),
                React.createElement(CnButton, { onClick: onReset }, $i18n.get({ id: 'Reset', dm: '重置', ns: 'CnFilter' }))) },
            React.createElement(SchemaField, { schema: schema, components: components }),
            normalFilterChild)));
}
CnFilter.displayName = 'CnFilter';
CnFilter.defaultProps = {
    formProps: {},
    isSticky: true,
    positionFix: true,
};
