import { __assign, __awaiter, __generator, __rest } from "tslib";
import * as React from 'react';
import { useField } from '@formily/react';
import { CnSelect, } from '@/components/cn-select';
import { handleRequestService } from './service';
import { useRequest } from 'ahooks';
import LRU from 'lru-cache';
import useInitRequest from './useInitRequest';
import { isFunction } from 'lodash';
var AsyncSelect = function (props, ref) {
    var baseSelectRef = React.useRef(null);
    var lru = React.useState(new LRU({
        max: 5000,
    }))[0];
    var _a = React.useState(false), searchLoading = _a[0], setSearchLoading = _a[1];
    var url = props.remoteUrl, _b = props.requestConfig, requestConfig = _b === void 0 ? {} : _b, _c = props.initRequestConfig, initRequestConfig = _c === void 0 ? {} : _c, propOnVisibleChange = props.onVisibleChange, otherProps = __rest(props, ["remoteUrl", "requestConfig", "initRequestConfig", "onVisibleChange"]);
    // 读取requestConfig
    var service = requestConfig.service, searchFormat = requestConfig.searchFormat, otherRequestConfig = __rest(requestConfig, ["service", "searchFormat"]);
    // 请求地址，优先requestConfig，其次remoteUrl
    var remoteUrl = requestConfig.url || url;
    // 请求服务封装
    var requestService = handleRequestService(requestConfig, remoteUrl);
    // 初始请求服务，用于数据回显
    var initRequestService = handleRequestService(initRequestConfig, (initRequestConfig === null || initRequestConfig === void 0 ? void 0 : initRequestConfig.url) || url);
    // form、filter中默认缓存数据
    var formField;
    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        formField = useField();
    }
    catch (e) {
        console.log('err-mes', e);
    }
    // 通过useRequest返回数据
    var _d = useRequest(requestService, __assign({ ready: !!(remoteUrl || service), staleTime: -1 }, otherRequestConfig)), runAsync = _d.runAsync, run = _d.run, mutate = _d.mutate, 
    // error,
    _e = _d.data, 
    // error,
    data = _e === void 0 ? [] : _e;
    React.useImperativeHandle(ref, function () {
        var _a;
        return (__assign({ mutateDataSource: mutate, sendRequest: run }, ((_a = baseSelectRef === null || baseSelectRef === void 0 ? void 0 : baseSelectRef.current) !== null && _a !== void 0 ? _a : {})));
    });
    // 组装的select props
    var insertSelectProps = {};
    insertSelectProps.dataSource = data;
    insertSelectProps.onVisibleChange = function (visible, reason) {
        if (isFunction(propOnVisibleChange)) {
            propOnVisibleChange(visible, reason, run);
        }
    };
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    var realSearch = otherProps.hasSearch || otherProps.showSearch;
    var enableOnSearch = realSearch && !otherProps.onSearch && props.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.searchLoading = searchLoading;
        insertSelectProps.onSearch = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSearchLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, runAsync(inputValue)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        setSearchLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
    }
    // 数据源缓存
    React.useEffect(function () {
        var _a;
        data === null || data === void 0 ? void 0 : data.forEach(function (item) {
            lru.set(item.value, Promise.resolve(item));
        });
        (_a = otherProps.dataSource) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
            lru.set(item.value, Promise.resolve(item));
        });
    }, [data, otherProps.dataSource, lru]);
    var value = props.value, filterLocal = props.filterLocal, useDetailValue = props.useDetailValue;
    // 根据value做数据回显
    useInitRequest({
        value: value,
        filterLocal: filterLocal,
        useDetailValue: useDetailValue,
        requestService: requestService,
        initRequestService: initRequestService,
        lru: lru,
        mutate: mutate,
    });
    return (
    // @ts-ignore ref
    React.createElement(CnSelect, __assign({ ref: baseSelectRef }, insertSelectProps, otherProps)));
};
var CnAsyncSelect = React.forwardRef(AsyncSelect);
CnAsyncSelect.displayName = 'CnAsyncSelect';
export default CnAsyncSelect;
