import { __assign, __rest, __spreadArray } from "tslib";
import React, { createContext, useContext } from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { isValid, clone } from '@formily/shared';
import { useField, useFieldSchema, RecordScope, RecordsScope, } from '@formily/react';
import { usePrefixCls } from '@/form/__builtins__';
import cls from 'classnames';
import './index.scss';
// @ts-ignore
var ArrayBaseContext = createContext(null);
// @ts-ignore
var ItemContext = createContext(null);
var takeRecord = function (val) { return (typeof val === 'function' ? val() : val); };
var useArray = function () {
    return useContext(ArrayBaseContext);
};
var useIndex = function (index) {
    var ctx = useContext(ItemContext);
    return ctx ? ctx.index : index;
};
var useRecord = function (record) {
    var ctx = useContext(ItemContext);
    return takeRecord(ctx ? ctx.record : record);
};
var getSchemaDefaultValue = function (schema) {
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'array')
        return [];
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'object')
        return {};
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'void') {
        for (var key in schema.properties) {
            var value = getSchemaDefaultValue(schema.properties[key]);
            if (isValid(value))
                return value;
        }
    }
};
var getDefaultValue = function (defaultValue, schema) {
    if (isValid(defaultValue))
        return clone(defaultValue);
    if (Array.isArray(schema === null || schema === void 0 ? void 0 : schema.items)) {
        return getSchemaDefaultValue(schema.items[0]);
    }
    return getSchemaDefaultValue(schema.items);
};
export var ArrayBase = function (props) {
    var field = useField();
    var schema = useFieldSchema();
    return (React.createElement(RecordsScope, { getRecords: function () { return field.value; } },
        React.createElement(ArrayBaseContext.Provider, { value: { field: field, schema: schema, props: props } }, props.children)));
};
ArrayBase.Item = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(ItemContext.Provider, { value: props },
        React.createElement(RecordScope, { getIndex: function () { return props.index; }, getRecord: function () { return takeRecord(props.record); } }, children)));
};
ArrayBase.Addition = function (props) {
    var _a, _b, _c;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var self = useField();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var array = useArray();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var schema = useFieldSchema();
    var maxItems = schema.maxItems;
    var maxItemDisabled = maxItems && ((_a = self.value) === null || _a === void 0 ? void 0 : _a.length) >= maxItems;
    if (!array)
        return null;
    if (((_b = array.field) === null || _b === void 0 ? void 0 : _b.pattern) !== 'editable' &&
        ((_c = array.field) === null || _c === void 0 ? void 0 : _c.pattern) !== 'disabled') {
        return null;
    }
    return (React.createElement(CnButton, __assign({ type: "normal", size: "large", fullWidth: true }, props, { disabled: maxItemDisabled || props.disabled, onClick: function (e) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            if ((_a = array.props) === null || _a === void 0 ? void 0 : _a.disabled)
                return;
            e.stopPropagation();
            var defaultValue = getDefaultValue(props.defaultValue, array.schema);
            if (props.method === 'unshift') {
                (_c = (_b = array.field) === null || _b === void 0 ? void 0 : _b.unshift) === null || _c === void 0 ? void 0 : _c.call(_b, defaultValue);
                (_e = (_d = array.props) === null || _d === void 0 ? void 0 : _d.onAdd) === null || _e === void 0 ? void 0 : _e.call(_d, 0);
            }
            else {
                (_g = (_f = array.field) === null || _f === void 0 ? void 0 : _f.push) === null || _g === void 0 ? void 0 : _g.call(_f, defaultValue);
                var newValue_1 = (_h = array === null || array === void 0 ? void 0 : array.field) === null || _h === void 0 ? void 0 : _h.value;
                // 新增一行时数据置空
                if (newValue_1 === null || newValue_1 === void 0 ? void 0 : newValue_1.length) {
                    newValue_1 = __spreadArray([], newValue_1, true);
                    newValue_1[((_k = (_j = array === null || array === void 0 ? void 0 : array.field) === null || _j === void 0 ? void 0 : _j.value) === null || _k === void 0 ? void 0 : _k.length) - 1] = defaultValue;
                    setTimeout(function () {
                        var _a;
                        (_a = array.field) === null || _a === void 0 ? void 0 : _a.setValue(newValue_1);
                    });
                }
                (_m = (_l = array.props) === null || _l === void 0 ? void 0 : _l.onAdd) === null || _m === void 0 ? void 0 : _m.call(_l, ((_p = (_o = array === null || array === void 0 ? void 0 : array.field) === null || _o === void 0 ? void 0 : _o.value) === null || _p === void 0 ? void 0 : _p.length) - 1);
            }
            if (props.onClick) {
                props.onClick(e);
            }
        } }),
        React.createElement(CnIcon, { type: "icon-add", size: "small", className: "table-addition-icon" }),
        props.title || self.title));
};
ArrayBase.Remove = React.forwardRef(function (props, ref) {
    var _a;
    var index = useIndex(props.index);
    var self = useField();
    var array = useArray();
    var prefixCls = usePrefixCls('formily-array-base');
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, __assign({ text: true, type: "primary" }, props, { ref: ref, onClick: function (e) {
            var _a, _b, _c, _d;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            (_b = (_a = array.field) === null || _a === void 0 ? void 0 : _a.remove) === null || _b === void 0 ? void 0 : _b.call(_a, index);
            (_d = (_c = array.props) === null || _c === void 0 ? void 0 : _c.onRemove) === null || _d === void 0 ? void 0 : _d.call(_c, index);
            if (props.onClick) {
                props.onClick(e);
            }
        } }),
        React.createElement(CnIcon
        // size="large"
        , { 
            // size="large"
            type: "icon-delete", className: cls("".concat(prefixCls, "-remove"), (self === null || self === void 0 ? void 0 : self.disabled) ? "".concat(prefixCls, "-remove-disabled") : '') })));
});
ArrayBase.Copy = React.forwardRef(function (props, ref) {
    var _a;
    var index = useIndex(props.index);
    var self = useField();
    var array = useArray();
    var prefixCls = usePrefixCls('formily-array-base');
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, __assign({ type: "primary", text: true }, props, { ref: ref, onClick: function (e) {
            var _a, _b, _c, _d, _e;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            var value = clone((_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.value[index]);
            var distIndex = index + 1;
            (_c = (_b = array.field) === null || _b === void 0 ? void 0 : _b.insert) === null || _c === void 0 ? void 0 : _c.call(_b, distIndex, value);
            (_e = (_d = array.props) === null || _d === void 0 ? void 0 : _d.onCopy) === null || _e === void 0 ? void 0 : _e.call(_d, distIndex);
            if (props.onClick) {
                props.onClick(e);
            }
        } }),
        React.createElement(CnIcon
        // size="large"
        , { 
            // size="large"
            type: "copy", className: cls("".concat(prefixCls, "-copy"), (self === null || self === void 0 ? void 0 : self.disabled) ? "".concat(prefixCls, "-copy-disabled") : '') })));
});
ArrayBase.useArray = useArray;
ArrayBase.useIndex = useIndex;
ArrayBase.useRecord = useRecord;
ArrayBase.mixin = function (target) {
    target.Index = ArrayBase.Index;
    target.SortHandle = ArrayBase.SortHandle;
    target.Addition = ArrayBase.Addition;
    target.Copy = ArrayBase.Copy;
    target.Remove = ArrayBase.Remove;
    target.useArray = ArrayBase.useArray;
    target.useIndex = ArrayBase.useIndex;
    target.useRecord = ArrayBase.useRecord;
    return target;
};
