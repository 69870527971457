import { __assign } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnPopover } from '../cn-popover';
export var CnTextEllipsis = function (props) {
    var style = props.style, className = props.className, children = props.children, showFolder = props.showFolder, line = props.line, hasTooltip = props.hasTooltip;
    var textRef = React.useRef(null);
    var _a = React.useState(false), isOverflow = _a[0], setIsOverflow = _a[1];
    var _b = React.useState(false), showAll = _b[0], setShowAll = _b[1];
    React.useEffect(function () {
        if (!(textRef === null || textRef === void 0 ? void 0 : textRef.current))
            return;
        if (textRef.current.scrollHeight > textRef.current.offsetHeight) {
            setIsOverflow(true);
        }
    }, []);
    var handleToggleFold = function () {
        setShowAll(!showAll);
    };
    var content = (React.createElement("div", { className: cx('cn-ui-m-text-ellipsis', {
            fold: showFolder && isOverflow,
        }) },
        React.createElement("div", { ref: textRef, className: cx([
                className,
                {
                    'cn-ui-m-text-ellipsis-inner': !showAll,
                },
            ]), style: __assign(__assign({}, style), { WebkitLineClamp: line }) }, children),
        showFolder && isOverflow ? (React.createElement("span", { className: "cn-ui-m-text-ellipsis-fold", onClick: handleToggleFold },
            React.createElement(CnIcon, { type: showAll ? 'triangle-up-fill' : 'triangle-down-fill' }))) : null));
    if (hasTooltip && isOverflow) {
        return (React.createElement(CnPopover, { content: children, placement: "top-start", trigger: "click", mode: "dark" }, content));
    }
    return content;
};
CnTextEllipsis.defaultProps = {
    line: 2,
    hasTooltip: true,
    showFolder: false,
};
CnTextEllipsis.displayName = 'CnTextEllipsis';
