import * as React from 'react';
function isNumberStr(str) {
    return typeof str === 'string' && !isNaN(parseFloat(str));
}
export function getNumFromStr(val) {
    return isNumberStr("".concat(val)) ? formatCurrency("".concat(val)) : val;
}
function formatCurrency(val) {
    return Number(val).toLocaleString('zh-CN');
}
export var FormatCurrencyWithUnit = function (props) {
    var value = props.value;
    if (typeof value === 'object') {
        var amount = value === null || value === void 0 ? void 0 : value.amount;
        var currency = value === null || value === void 0 ? void 0 : value.currency;
        return (React.createElement(React.Fragment, null,
            getNumFromStr(amount),
            " ",
            currency));
    }
    return value ? React.createElement(React.Fragment, null, getNumFromStr(value)) : null;
};
